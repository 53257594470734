import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

const ProtectedRoute = ({ disallow, children = [] }) => {
  let flag = false;

  localStorage.getItem("token") ? (flag = true) : (flag = false);
  const location = useLocation();
  const navigate = useNavigate();
  const isDisallowed =
    flag && disallow.some((route) => location.pathname.includes(route));

  useEffect(() => {
    if (isDisallowed) {
      navigate({
        pathname: "/admin-dashboard",
        state: { from: location },
      });
    }
  }, [isDisallowed, navigate, location.pathname]);
  return !isDisallowed && flag ? children : null;
};

export default ProtectedRoute;
