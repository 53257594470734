import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "../Home/Footer";
import Sidebar from "./Sidebar";
import Chart from "./Charts/Chart";
import LoadingSpinner from "../LoadingSpinner";

const ShodasVargaCharts = () => {
  let birthDetails = JSON.parse(sessionStorage.getItem("details"));
  const [details, setDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const ShodasVargaCharts = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`/shodasvarga-charts`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          year: birthDetails?.year,
          month: birthDetails?.month,
          date: birthDetails?.date,
          hour: birthDetails?.hour,
          min: birthDetails?.min,
          sec: birthDetails?.sec,
          latitude: birthDetails?.latitude,
          longitude: birthDetails?.longitude,
        }),
      });
      const response = await res.json();

      if (res.status === 400 || !response) {
        window.alert(response.message);
        setIsLoading(false);
      }
      if (res.status === 200) {
        setDetails(response);
        setIsLoading(false);
      }
    } catch (e) {}
  };
  useEffect(() => {
    ShodasVargaCharts();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  } else if (!isLoading && details) {
    return (
      <>
        <div
          className="wrapper boxed-wrapper"
          style={{ height: "auto", minHeight: "100%" }}
        >
          <Header />
          <Sidebar />
          <div className="content-wrapper">
            <div className="content-header sty-one">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-12">
                    <Chart
                      name={"birthChart"}
                      property={"Lagna - D1"}
                      chartName={details?.laganChart}
                      hindiChartName={details?.hindiLaganChart}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-12">
                    <Chart
                      name={"horaChart"}
                      property={"Wealth - D2"}
                      chartName={details?.horaChart}
                      hindiChartName={details?.hindiHoraChart}
                    />
                  </div>
                  <div className="col-6">
                    <Chart
                      name={"drekkanaChart"}
                      chartName={details?.drekkanaChart}
                      property={"Siblings - D3"}
                      hindiChartName={details?.hindiDrekkanaChart}
                    />
                  </div>
                  <div className="col-6">
                    <Chart
                      name={"chaturthamshaChart"}
                      property={"Luck - D4"}
                      chartName={details?.chaturthamshaChart}
                      hindiChartName={details?.hindiChaturthamshaChart}
                    />
                  </div>
                  <div className="col-6">
                    <Chart
                      name={"saptamamshaChart"}
                      property={"Children - D7"}
                      chartName={details?.saptamamshaChart}
                      hindiChartName={details?.hindiSaptamamshaChart}
                    />
                  </div>
                  <div className="col-6">
                    <Chart
                      name={"navasamaChart"}
                      property={"Spouse - D9"}
                      chartName={details?.navasamaChart}
                      hindiChartName={details?.hindiNavasamaChart}
                    />
                  </div>
                  <div className="col-6">
                    <Chart
                      name={"dasmanshChart"}
                      property={"Profession - D10"}
                      chartName={details?.dasmanshChart}
                      hindiChartName={details?.hindiDasmanshChart}
                    />
                  </div>
                  <div className="col-6">
                    <Chart
                      name={"dwadashamamshaChart"}
                      property={"Parents - D12"}
                      chartName={details?.dwadashamamshaChart}
                      hindiChartName={details?.hindiDwadashamamshaChart}
                    />
                  </div>
                  <div className="col-6">
                    <Chart
                      name={"shodashamshaChart"}
                      property={"Vehicles - D16"}
                      chartName={details?.shodashamshaChart}
                      hindiChartName={details?.hindiShodashamshaChart}
                    />
                  </div>
                  <div className="col-6">
                    <Chart
                      name={"vimshamshaChart"}
                      property={"Religious Inclinations - D20"}
                      chartName={details?.vimshamshaChart}
                      hindiChartName={details?.hindiVimshamshaChart}
                    />{" "}
                  </div>
                  <div className="col-6">
                    <Chart
                      name={"saptavimshmshaChart"}
                      property={"Strength - D27"}
                      chartName={details?.saptavimshmshaChart}
                      hindiChartName={details?.hindiSaptavimshmshaChart}
                    />
                  </div>
                  <div className="col-6">
                    <Chart
                      name={"chaturvimshamshaChart"}
                      property={"Education - D24"}
                      chartName={details?.chaturvimshamshaChart}
                      hindiChartName={details?.hindiChaturvimshamshaChart}
                    />
                  </div>
                  <div className="col-6">
                    <Chart
                      name={"trimshamshaChart"}
                      property={"Misfortune - D30"}
                      chartName={details?.trimshamshaChart}
                      hindiChartName={details?.hindiTrimshamshaChart}
                    />
                  </div>
                  <div className="col-6">
                    <Chart
                      name={"khavedamshaChart"}
                      property={"Auspicious Result - D40"}
                      chartName={details?.khavedamshaChart}
                      hindiChartName={details?.hindiKhavedamshaChart}
                    />{" "}
                  </div>
                  <div className="col-6">
                    <Chart
                      name={"akshvedamshaChart"}
                      property={"General Well Being - D45"}
                      chartName={details?.akshvedamshaChart}
                      hindiChartName={details?.hindiAkshvedamshaChart}
                    />
                  </div>
                  <div className="col-6">
                    <Chart
                      name={"shashtiamshaChart"}
                      property={"General Well Being - D60"}
                      chartName={details?.shashtiamshaChart}
                      hindiChartName={details?.hindiShashtiamshaChart}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};
export default ShodasVargaCharts;
