import { useTranslation } from "react-i18next";

const PranDasha = (props) => {
  const { t } = useTranslation();
  let data = props.pranDasha;
  let dataLength = data.length;
  return (
    <>
      <div className="content">
        <div className="card card-shadow">
          <div className="card-header bg-blue">
            <h5 className="mb-0 text-center boldFont">{t("pranDasha")}</h5>
          </div>

          <div className="row m-3">
            <div className="col-4">
              {data?.slice(0, dataLength / 8)?.map((val, index) => {
                return (
                  <>
                    <h5>{Object.keys(val)}</h5>
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead></thead>
                        <tbody>
                          {Object.values(val)?.map((value, ind) => {
                            return value?.map((planet, no) => {
                              return (
                                <>
                                  <tr>
                                    <th scope="row">
                                      {t(`${Object.keys(planet)}`)}
                                    </th>
                                    <td>{Object.values(planet)}</td>
                                  </tr>
                                </>
                              );
                            });
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                );
              })}
            </div>

            <div className="col-4">
              {data
                ?.slice(dataLength / 8, dataLength / 4)
                ?.map((val, index) => {
                  return (
                    <>
                      <h5>{Object.keys(val)}</h5>
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead></thead>
                          <tbody>
                            {Object.values(val)?.map((value, ind) => {
                              return value?.map((planet, no) => {
                                return (
                                  <>
                                    <tr>
                                      <th scope="row">
                                        {t(`${Object.keys(planet)}`)}
                                      </th>
                                      <td>{Object.values(planet)}</td>
                                    </tr>
                                  </>
                                );
                              });
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  );
                })}
            </div>

            <div className="col-4">
              {data
                ?.slice(dataLength / 4, dataLength / 2)
                ?.map((val, index) => {
                  return (
                    <>
                      <h5>{Object.keys(val)}</h5>
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead></thead>
                          <tbody>
                            {Object.values(val)?.map((value, ind) => {
                              return value?.map((planet, no) => {
                                return (
                                  <>
                                    <tr>
                                      <th scope="row">
                                        {t(`${Object.keys(planet)}`)}
                                      </th>
                                      <td>{Object.values(planet)}</td>
                                    </tr>
                                  </>
                                );
                              });
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  );
                })}
            </div>

            <div className="col-4">
              {data
                ?.slice(dataLength / 2, dataLength / 1.5)
                ?.map((val, index) => {
                  return (
                    <>
                      <h5>{Object.keys(val)}</h5>
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead></thead>
                          <tbody>
                            {Object.values(val)?.map((value, ind) => {
                              return value?.map((planet, no) => {
                                return (
                                  <>
                                    <tr>
                                      <th scope="row">
                                        {t(`${Object.keys(planet)}`)}
                                      </th>
                                      <td>{Object.values(planet)}</td>
                                    </tr>
                                  </>
                                );
                              });
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  );
                })}
            </div>

            <div className="col-4">
              {data?.slice(dataLength / 1.5, dataLength)?.map((val, index) => {
                return (
                  <>
                    <h5>{Object.keys(val)}</h5>
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead></thead>
                        <tbody>
                          {Object.values(val)?.map((value, ind) => {
                            return value?.map((planet, no) => {
                              return (
                                <>
                                  <tr>
                                    <th scope="row">
                                      {t(`${Object.keys(planet)}`)}
                                    </th>
                                    <td>{Object.values(planet)}</td>
                                  </tr>
                                </>
                              );
                            });
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PranDasha;
