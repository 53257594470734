import l from "../../images/high-logo.jpg";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import React, { useState } from "react";

const Header = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  let flag = false;
  let userDetails = localStorage.getItem("token");

  userDetails ? (flag = true) : (flag = false);
  let user = JSON.parse(userDetails);

  function changeLanguage(e) {
    localStorage.setItem("language", e.target.value);
    i18n.changeLanguage(e.target.value);
  }
  const hide = () => {
    const ele = document.getElementById("body");
    let a = document.querySelector(".text-center>img");

    ele.classList.toggle("sidebar-collapse");
    a.classList.toggle("user-sidebar-img");
  };

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const Check = () => {
    let element = document.getElementById("toggleButton");
    //element.classList.toggle("collapsed");
    element.setAttribute("aria-expanded", "true");
    let ele = document.getElementById("navbarSupportedContent");
    ele.classList.toggle("show");
  };

  const [showMediaIcons, setShowMediaIcons] = useState(false);

  return (
    <>
      <header className="main-header">
        <a href="#" className="logo blue-bg">
          <span className="logo-mini">
            <img src={l} alt="logo" style={{ height: "40px" }} />
          </span>
          <span className="logo-lg">
            <img src={l} alt="logo" style={{ height: "40px" }} />
          </span>{" "}
        </a>

        <nav className="navbar blue-bg navbar-static-top">
          <ul className="nav navbar-nav pull-left">
            <li onClick={hide}>
              <a
                className="sidebar-toggle"
                data-toggle="push-menu"
                href="#"
              ></a>
            </li>
            <li></li>
          </ul>
          <div className="pull-left search-box"></div>
          {!flag ? (
            <div className="navbar-custom-menu">
              <ul className="nav navbar-nav">
                <ul className="navbar-nav me-auto">
                  <li
                    className="nav-item dropdown mt-3.5 m-4"
                    onClick={() => navigate("/")}
                    // style={{ cursor: "default" }}
                  >
                    Home
                  </li>
                </ul>

                <ul className="navbar-nav me-auto">
                  <li
                    className="nav-item dropdown mt-3.5 m-4"
                    onClick={() => navigate("/plan")}
                    // style={{ cursor: "default" }}
                  >
                    Membership Plans
                  </li>
                </ul>
                <ul className="navbar-nav me-auto">
                  <li
                    className="nav-item dropdown mt-3.5 m-4"
                    onClick={() => navigate("/contact-us")}
                  >
                    Contact Us
                  </li>
                </ul>
                <ul className="navbar-nav me-auto">
                  <li
                    className="nav-item dropdown mt-3.5 m-4"
                    onClick={() => navigate("/login")}
                    // style={{ cursor: "default" }}
                  >
                    Login
                  </li>
                </ul>

                <ul className="navbar-nav me-auto">
                  <li className="nav-item dropdown mt-3.5 m-4">
                    <select
                      value={localStorage.getItem("language")}
                      onChange={changeLanguage}
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        border: "none",
                        width: "90px",
                      }}
                    >
                      <option value="en">English</option>
                      <option value="hi">Hindi</option>
                    </select>
                  </li>
                </ul>
              </ul>
            </div>
          ) : (
            <div className="navbar-custom-menu">
              <ul className="nav navbar-nav">
                <ul className="navbar-nav me-auto">
                  <li
                    className="nav-item dropdown mt-3.5 m-4"
                    onClick={() => navigate("/")}
                    // style={{ cursor: "default" }}
                  >
                    Home
                  </li>
                </ul>

                <ul className="navbar-nav me-auto">
                  <li
                    className="nav-item dropdown mt-3.5 m-4"
                    onClick={() => navigate("/plan")}
                    // style={{ cursor: "default" }}
                  >
                    Membership Plans
                  </li>
                </ul>
                <ul className="navbar-nav me-auto">
                  <li
                    className="nav-item dropdown mt-3.5 m-4"
                    onClick={() => navigate("/contact-us")}
                  >
                    Contact Us
                  </li>
                </ul>

                <ul className="navbar-nav me-auto">
                  <li className="nav-item dropdown mt-3.5 m-4">
                    <select
                      value={localStorage.getItem("language")}
                      onChange={changeLanguage}
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        border: "none",
                        width: "90px",
                      }}
                    >
                      <option value="e">Choose Language</option>
                      <option value="en">English</option>
                      <option value="hi">Hindi</option>
                    </select>
                  </li>
                </ul>

                <li className="dropdown user user-menu p-ph-res">
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    {" "}
                    <img
                      src={
                        "https://jyotishpunj.com/astrologer/profile/" +
                        user?.astrologer_photo
                      }
                      className="user-image"
                      alt="User Image"
                    />{" "}
                    <span className="hidden-xs">{user.astrologer_name}</span>{" "}
                  </a>
                  <ul className="dropdown-menu">
                    <li className="user-header">
                      <div className="pull-left user-img">
                        <img
                          src={
                            "https://jyotishpunj.com/astrologer/profile/" +
                            user?.astrologer_photo
                          }
                          className="img-responsive img-circle"
                          alt="User"
                        />
                      </div>
                      <p className="text-left">
                        {user?.astrologer_name}{" "}
                        <small>{user?.astrologer_email}</small>
                      </p>
                    </li>
                    <li>
                      <a href="/admin-profile">
                        <i className="fa fa-user-circle-o"></i> My Profile
                      </a>
                    </li>

                    <li>
                      <a href="/admin-dashboard">
                        <i className="fa fa-dashboard"></i> My Dashboard
                      </a>
                    </li>

                    <li onClick={() => logout()}>
                      <a href="#">
                        <i className="fa fa-power-off"></i> Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          )}
        </nav>
      </header>
    </>
  );
};

export default Header;
