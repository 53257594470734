import { useTranslation } from "react-i18next";
import "font-awesome/css/font-awesome.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAtom,
  faFile,
  faHeartCircleBolt,
} from "@fortawesome/free-solid-svg-icons";

const Sidebar = () => {
  const { t } = useTranslation();
  let getData = localStorage.getItem("token");
  const user = JSON.parse(getData);

  const showActive = () => {
    const ele = document.getElementById("list");

    ele.classList.toggle("active");
  };

  const showDasha = () => {
    const ele = document.getElementById("dasha");
    ele.classList.toggle("active");
  };

  const subMenu = () => {
    const element = document.getElementById("sub-menu");
    element.classList.toggle("active");
  };

  let birthDetails = JSON.parse(sessionStorage.getItem("details"));
  return (
    <>
      <aside
        className="main-sidebar"
        style={{ height: "auto", minHeight: "100vh" }}
      >
        <div className="sidebar">
          <div className="user-panel">
            <div className="image text-center">
              <img
                src={
                  "https://jyotishpunj.com/astrologer/profile/" +
                  user?.astrologer_photo
                }
                className="img-circle"
                alt="User Image"
              />
            </div>
            <div className="info">
              <p>{user?.astrologer_name}</p>
            </div>
          </div>

          <ul className="sidebar-menu" data-widget="tree">
            <li className="header">{/*<h5>{t("kundaliDetails")}</h5>*/}</li>

            <li className="treeview">
              <a href="/admin-dashboard">
                {" "}
                <i className="fa fa-dashboard"></i>{" "}
                <span>{t("dashboard")}</span>{" "}
              </a>
            </li>

            <ul className="sidebar-menu" data-widget="tree">
              <li className="treeview" onClick={showActive} id="list">
                {" "}
                <a href="/admin-profile">
                  {" "}
                  <i className="fa fa-user"></i> <span>{t("profile")}</span>{" "}
                  <span className="pull-right-container"> </span>{" "}
                </a>
              </li>
            </ul>

            <li className="treeview">
              <a href="/birth-details-input">
                {" "}
                <i className="fa fa-file-text"></i>{" "}
                <span>{t("generateKundali")}</span>{" "}
              </a>
            </li>

            <li className="treeview">
              <a href="/kundali-list">
                {" "}
                <i className="fa fa-list-alt"></i>
                <span>{t("ourKundali")}</span>{" "}
              </a>
            </li>

            <li className="treeview">
              <a href="/matchkundali">
                {" "}
                <FontAwesomeIcon icon={faHeartCircleBolt} />
                {/*<i*/}
                {/*  className="fa fa-asl-interpreting"*/}
                {/*  style={{ fontSize: "16px" }}*/}
                {/*></i>*/}
                <span> {t("matchKundali")}</span>{" "}
              </a>
            </li>

            <li className="treeview">
              <a href="/subscription-details">
                {" "}
                <i
                  className="fa fa-shopping-cart"
                  style={{ fontSize: "16px" }}
                ></i>
                <span> {t("subscription")}</span>{" "}
              </a>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
