import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { useTranslation } from "react-i18next";
import Header from "./Admin/Header";

import Footer from "./Home/Footer";
import Sidebar from "./Admin/Sidebar";
import { usePlacesWidget } from "react-google-autocomplete";

const apiKey = "AIzaSyBUpTf5-NWacvzlIW12l9XcnHgEeVM_u98";

const extractAddress = (address) => {
  let city = address?.formatted_address;
  let latitude = address?.geometry?.location?.lat();
  let longitude = address?.geometry?.location?.lng();
  return { city, latitude, longitude };
};

const BirthDetails = () => {
  const { t } = useTranslation();

  const [boyAddress, setBoyAddress] = useState({});
  const [girlAddress, setGirlAddress] = useState({});

  const d = new Date();
  let lang = localStorage.getItem("language") || "en";
  const navigate = useNavigate();
  const [birthDetailsData, setBirthDetailsData] = useState({
    boyName: "",
    girlName: "",
    boyYear: d.getFullYear(),
    boyMonth: d.getMonth() + 1,
    boyDate: d.getDate(),
    boyHour: d.getHours(),
    boyMin: d.getMinutes(),
    boySec: d.getSeconds(),
    girlYear: d.getFullYear(),
    girlMonth: d.getMonth() + 1,
    girlDate: d.getDate(),
    girlHour: d.getHours(),
    girlMin: d.getMinutes(),
    girlSec: d.getSeconds(),
  });

  let name, value;
  const handleBirthDetailsInputs = (e) => {
    name = e.target.name;
    value = e.target.value;

    setBirthDetailsData({ ...birthDetailsData, [name]: value });
  };

  const BirthDetails = async (e) => {
    let boyRegion = extractAddress(boyAddress);
    let girlRegion = extractAddress(girlAddress);
    console.log({ boyRegion, girlRegion });

    e.preventDefault();
    try {
      const {
        boyYear,
        boyMonth,
        boyDate,
        boyHour,
        boyMin,
        boySec,
        girlYear,
        girlMonth,
        girlDate,
        girlHour,
        girlMin,
        girlSec,
        boyName,
        girlName,
      } = birthDetailsData;
      const res = await fetch(`/match-kundali`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          boyYear,
          boyMonth,
          boyDate,
          boyHour,
          boyMin,
          boySec,
          girlYear,
          girlMonth,
          girlDate,
          girlHour,
          girlMin,
          girlSec,

          boyLatitude: boyRegion?.latitude,
          boyLongitude: boyRegion?.longitude,

          girlLatitude: girlRegion?.latitude,
          girlLongitude: girlRegion?.longitude,
        }),
      });
      const response = await res.json();
      console.log({ response });
      if (res.status === 400 || !response) {
        window.alert(response.message);
      }
      if (res.status === 200) {
        let obj = {
          ...response,
          ...birthDetailsData,
          boyCity: boyRegion?.city,
          girlCity: girlRegion?.city,
          boyLongitude: boyRegion?.longitude,
          boyLatitude: boyRegion?.latitude,
          girlLongitude: girlRegion?.longitude,
          girlLatitude: girlRegion?.latitude,
          boyName: boyName,
          girlName: girlName,
        };

        sessionStorage.setItem("matchKundaliDetails", JSON.stringify(obj));
        navigate("/matchkundalidetails");
      }
    } catch (e) {}
  };

  return (
    <>
      <div className="wrapper boxed-wrapper">
        <Header />
        <Sidebar />
        <div
          className="content-wrapper"
          style={{ height: "auto", minHeight: "93vh" }}
        >
          <form onSubmit={BirthDetails}>
            <div className="content-header sty-one">
              <h1>{t("kundaliMatching")}</h1>
              <ol className="breadcrumb">
                <li>
                  <a href="#">{t("home")}</a>
                </li>
                <li>
                  <i className="fa fa-angle-right"></i>
                  {t("kundaliMatching")}
                </li>
              </ol>
            </div>

            <div className="row m-2 p-2">
              <div className="col-12 col-md-6 ">
                {/*<div className="content">*/}
                <div className="card">
                  <div className="card-header bg-blue">
                    <h5 className="mb-0 text-center boldFont">
                      {t("boyBirthDetails")}
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <label className="control-label">{t("name")}</label>
                      <input
                        className="form-control"
                        placeholder="Full Name"
                        type="text"
                        name="boyName"
                        value={birthDetailsData.boyName}
                        onChange={handleBirthDetailsInputs}
                        style={{ width: "97%", margin: "10px" }}
                      />
                      <div className="col-md-4">
                        <div className="form-group has-feedback">
                          <label className="control-label">{t("day")}</label>
                          <input
                            className="form-control"
                            placeholder="Day"
                            type="number"
                            name="boyDate"
                            value={birthDetailsData.boyDate}
                            onChange={handleBirthDetailsInputs}
                          />
                          <span
                            className="form-control-feedback"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group has-feedback">
                          <label className="control-label">{t("month")}</label>
                          <input
                            className="form-control"
                            placeholder="Month"
                            type="number"
                            name="boyMonth"
                            value={birthDetailsData.boyMonth}
                            onChange={handleBirthDetailsInputs}
                          />
                          <span
                            className="form-control-feedback"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group has-feedback">
                          <label className="control-label">{t("year")}</label>
                          <input
                            className="form-control"
                            placeholder="Year"
                            type="number"
                            name="boyYear"
                            value={birthDetailsData.boyYear}
                            onChange={handleBirthDetailsInputs}
                          />
                          <span
                            className="form-control-feedback"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group has-feedback">
                          <label className="control-label">{t("hours")}</label>
                          <input
                            className="form-control"
                            placeholder="hours"
                            type="number"
                            name="boyHour"
                            value={birthDetailsData.boyHour}
                            onChange={handleBirthDetailsInputs}
                          />
                          <span
                            className="form-control-feedback"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group has-feedback">
                          <label className="control-label">
                            {t("minutes")}
                          </label>
                          <input
                            className="form-control"
                            placeholder="minutes"
                            type="number"
                            name="boyMin"
                            value={birthDetailsData.boyMin}
                            onChange={handleBirthDetailsInputs}
                          />
                          <span
                            className="form-control-feedback"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group has-feedback">
                          <label className="control-label">
                            {t("seconds")}
                          </label>
                          <input
                            className="form-control"
                            placeholder="seconds"
                            type="number"
                            name="boySec"
                            value={birthDetailsData.boySec}
                            onChange={handleBirthDetailsInputs}
                          />
                          <span
                            className="form-control-feedback"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </div>
                      <label className="control-label">{t("place")}</label>
                      <Autocomplete
                        apiKey={apiKey}
                        onPlaceSelected={(address) => {
                          setBoyAddress(address);
                        }}
                        className="form-control"
                        style={{ width: "97%", margin: "10px" }}
                      />
                    </div>
                  </div>
                </div>
                {/*</div>*/}
              </div>
              <div className="col-12 col-md-6 ">
                {/*<div className="content">*/}
                <div className="card">
                  <div className="card-header bg-blue">
                    <h5 className="mb-0 text-center boldFont">
                      {t("girlBirthDetails")}
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <label className="control-label">{t("name")}</label>
                      <input
                        className="form-control"
                        placeholder="Full Name"
                        type="text"
                        name="girlName"
                        value={birthDetailsData.girlName}
                        onChange={handleBirthDetailsInputs}
                        style={{ width: "97%", margin: "10px" }}
                      />
                      <div className="col-md-4">
                        <div className="form-group has-feedback">
                          <label className="control-label">{t("day")}</label>
                          <input
                            className="form-control"
                            placeholder="Day"
                            type="number"
                            name="girlDate"
                            value={birthDetailsData.girlDate}
                            onChange={handleBirthDetailsInputs}
                          />
                          <span
                            className="form-control-feedback"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group has-feedback">
                          <label className="control-label">{t("month")}</label>
                          <input
                            className="form-control"
                            placeholder="Month"
                            type="number"
                            name="girlMonth"
                            value={birthDetailsData.girlMonth}
                            onChange={handleBirthDetailsInputs}
                          />
                          <span
                            className="form-control-feedback"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group has-feedback">
                          <label className="control-label">{t("year")}</label>
                          <input
                            className="form-control"
                            placeholder="Year"
                            type="number"
                            name="girlYear"
                            value={birthDetailsData.girlYear}
                            onChange={handleBirthDetailsInputs}
                          />
                          <span
                            className="form-control-feedback"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group has-feedback">
                          <label className="control-label">{t("hours")}</label>
                          <input
                            className="form-control"
                            placeholder="hours"
                            type="number"
                            name="girlHour"
                            value={birthDetailsData.girlHour}
                            onChange={handleBirthDetailsInputs}
                          />
                          <span
                            className="form-control-feedback"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group has-feedback">
                          <label className="control-label">
                            {t("minutes")}
                          </label>
                          <input
                            className="form-control"
                            placeholder="minutes"
                            type="number"
                            name="girlMin"
                            value={birthDetailsData.girlMin}
                            onChange={handleBirthDetailsInputs}
                          />
                          <span
                            className="form-control-feedback"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group has-feedback">
                          <label className="control-label">
                            {t("seconds")}
                          </label>
                          <input
                            className="form-control"
                            placeholder="seconds"
                            type="number"
                            name="girlSec"
                            value={birthDetailsData.girlSec}
                            onChange={handleBirthDetailsInputs}
                          />
                          <span
                            className="form-control-feedback"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </div>
                      <label className="control-label">{t("place")}</label>
                      <Autocomplete
                        apiKey={apiKey}
                        onPlaceSelected={(address) => {
                          setGirlAddress(address);
                        }}
                        className="form-control"
                        style={{ width: "97%", margin: "10px" }}
                      />
                    </div>
                  </div>
                </div>
                {/*</div>*/}
              </div>
            </div>
            <div className="col-md-12 text-center">
              <button type="submit" className="btn btn-primary btnStyle mb-5">
                {t("submit")}
              </button>
            </div>
          </form>
        </div>{" "}
      </div>
      <Footer />
    </>
  );
};

export default BirthDetails;
