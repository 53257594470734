import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import Footer from "../../Home/Footer";
import Sidebar from "../Sidebar";
import Chart from "./Chart";
import dms from "../../../utils/DMS";
import Header from "../Header";
import LoadingSpinner from "../../LoadingSpinner";

const BasicCharts = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  let birthDetails = JSON.parse(sessionStorage.getItem("details"));

  const [details, setDetails] = useState();

  const getCharts = async (e) => {
    setIsLoading(true);
    try {
      const res = await fetch(`/basic-charts`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          year: birthDetails?.year,
          month: birthDetails?.month,
          date: birthDetails?.date,
          hour: birthDetails?.hour,
          min: birthDetails?.min,
          sec: birthDetails?.sec,
          latitude: birthDetails?.latitude,
          longitude: birthDetails?.longitude,
        }),
      });
      const response = await res.json();

      if (res.status === 400 || !response) {
        window.alert(response.message);
        setIsLoading(false);
      }
      if (res.status === 200) {
        setDetails(response);
        setIsLoading(false);
      }
    } catch (e) {}
  };

  useEffect(() => {
    getCharts().then((r) => console.log(r));
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  } else if (!isLoading && details) {
    return (
      <>
        <div
          className="wrapper boxed-wrapper"
          style={{ height: "auto", minHeight: "93vh" }}
        >
          <Header />
          <Sidebar />
          <div className="content-wrapper">
            <div className="content-header sty-one">
              <div className="container">
                <section>
                  <div className="tabs-satrt">
                    <div className="left-atab">
                      <ul
                        className="nav nav-tabs start-project-ul"
                        id="myTab"
                        role="tablist"
                      >
                        <li role="presentation" className="nav-item">
                          <a
                            href="#step1"
                            className="nav-link active st1"
                            data-bs-toggle="tab"
                            aria-controls="step1"
                            role="tab"
                            title="Step 1"
                          >
                            Lagan Chart
                          </a>
                        </li>
                        <li role="presentation" className="nav-item">
                          <a
                            href="#step2"
                            className="nav-link st2"
                            data-bs-toggle="tab"
                            aria-controls="step2"
                            role="tab"
                            title="Step 2"
                          >
                            Moon Chart
                          </a>
                        </li>
                        <li role="presentation" className="nav-item">
                          <a
                            href="#step3"
                            className="nav-link st3"
                            data-bs-toggle="tab"
                            aria-controls="step3"
                            role="tab"
                            title="Step 3"
                          >
                            Navasama Chart
                          </a>
                        </li>

                        <li role="presentation" className="nav-item lstli">
                          <a
                            href="#step4"
                            className="nav-link st4"
                            data-bs-toggle="tab"
                            aria-controls="step4"
                            role="tab"
                            title="Complete"
                          >
                            Sun Chart
                          </a>
                        </li>
                        <li role="presentation" className="nav-item lstli">
                          <a
                            href="#step5"
                            className="nav-link st4"
                            data-bs-toggle="tab"
                            aria-controls="step5"
                            role="tab"
                            title="Complete"
                          >
                            Chalit Chart
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="tabs-pro form-style">
                      <div className="tab-content">
                        <div
                          className="tab-pane  active"
                          role="tabpanel"
                          id="step1"
                        >
                          <div className="card-header bg-blue">
                            <h5 className="p-2 text-center boldFont">
                              {" "}
                              {t("birthChartTable")}
                            </h5>
                          </div>
                          {/*<h5 className="text-black heading boldFont">*/}
                          {/*  {t("birthChartTable")}*/}
                          {/*</h5>*/}

                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">{t("graha")}</th>

                                  <th scope="col">{t("degreesDMS")}</th>

                                  <th scope="col">{t("nakshatra")}</th>
                                  <th scope="col">{t("pada")}</th>
                                  <th scope="col">{t("rashi")}</th>
                                  <th scope="col">{t("retrograde")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object?.entries(
                                  details?.planetPositions?.meta
                                )?.map(([k, v]) => {
                                  return (
                                    <tr>
                                      <td>{t(`${v?.graha}`)}</td>

                                      <td>{dms(v?.longitude % 30)}</td>

                                      {/*<td>{dms(v?.longitude % 30)}</td>*/}

                                      <td>{t(`${v?.nakshatra?.name}`)}</td>
                                      <td>{v?.nakshatra?.pada}</td>
                                      <td>{t(`${v?.rashi}`)}</td>
                                      <td>{t(`${v?.isRetrograde}`)}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          <Chart
                            name={"birthChart"}
                            chartName={details?.laganChart}
                            hindiChartName={details?.hindiLaganChart}
                          />
                        </div>

                        <div className="tab-pane" role="tabpanel" id="step2">
                          <Chart
                            name={"moonChart"}
                            chartName={details?.moonChart}
                            hindiChartName={details?.hindiMoonChart}
                          />
                        </div>

                        <div className="tab-pane" role="tabpanel" id="step3">
                          <Chart
                            name={"navasamaChart"}
                            chartName={details?.navasamaChart}
                            hindiChartName={details?.hindiNavasamaChart}
                          />
                        </div>

                        <div className="tab-pane" role="tabpanel" id="step4">
                          <Chart
                            name={"sunChart"}
                            chartName={details?.sunChart}
                            hindiChartName={details?.hindiSunChart}
                          />
                        </div>

                        <div className="tab-pane" role="tabpanel" id="step5">
                          <Chart
                            name={"chalitChart"}
                            chartName={details?.chalitChart}
                            hindiChartName={details?.hindiChalitChart}
                          />
                        </div>

                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};
export default BasicCharts;
