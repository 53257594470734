import Autocomplete from "react-google-autocomplete";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { extractAddress } from "../ExtractAddress";
import astrology from "../../images/Banner/input-background.png";
import Header from "./Header";
import Footer from "../Home/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const apiKey = "AIzaSyBUpTf5-NWacvzlIW12l9XcnHgEeVM_u98";

const BirthDetailsInput = () => {
  const { t } = useTranslation();
  const [address, setAddress] = useState({});
  const navigate = useNavigate();

  const lang = localStorage.getItem("language") || "en";
  const d = new Date();
  const [birthDetailsData, setBirthDetailsData] = useState({
    fullName: "",
    gender: "",
    year: d.getFullYear(),
    month: d.getMonth() + 1,
    date: d.getDate(),
    hour: d.getHours(),
    min: d.getMinutes(),
    sec: d.getSeconds(),
  });

  let name, value;
  const handleBirthDetailsInputs = (e) => {
    name = e.target.name;
    value = e.target.value;

    setBirthDetailsData({ ...birthDetailsData, [name]: value });
  };

  const BirthDetails = async (e) => {
    let place = await extractAddress(address);

    e.preventDefault();
    try {
      const { year, month, date, hour, min, sec } = birthDetailsData;
      const res = await fetch(`/birthChart`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          year,
          month,
          date,
          hour,
          min,
          sec,
          latitude: place?.latitude,
          longitude: place?.longitude,
        }),
      });
      const response = await res.json();

      if (res.status === 400 || !response) {
        window.alert(response.message);
      }
      if (res.status === 200) {
        let obj = {
          ...birthDetailsData,
          latitude: place?.latitude,
          longitude: place?.longitude,
          city: place?.city,
        };

        sessionStorage.setItem("details", JSON.stringify(obj));
        await SaveList();
        navigate("/user-dashboard");
      }
    } catch (e) {}
  };

  let userDetails = localStorage.getItem("token");

  let user = JSON.parse(userDetails);
  const SaveList = async () => {
    let place = await extractAddress(address);

    try {
      const { year, month, date, hour, min, sec, fullName, gender } =
        birthDetailsData;
      const res = await axios({
        method: "post",
        url: "https://jyotishpunj.com/api/jp-astrologer/save-kundli",
        data: {
          astrologer_id: user?.astrologer_id,
          year,
          month,
          date,
          hour,
          min,
          sec,
          latitude: place?.latitude,
          longitude: place?.longitude,
          name: fullName,
          gender: gender,
          language: lang,
          location: place?.city,
        },
      });

      if (res.status === 201 && res.data.status === "failed") {
        window.alert(res.data.message);
      }
      if (res.status === 200) {
      }
    } catch (e) {}
  };

  return (
    <>
      <Header />
      <div
        className="wrapper boxed-wrapper"
        style={{ height: "auto", minHeight: "100%" }}
      >
        <div className="image text-center">
          <img src={astrology} style={{ width: "1600px" }} alt="User Image" />
        </div>

        <div className="content-header sty-one">
          <div className="content">
            <div className="card mt-4">
              <div className="card-header bg-blue">
                <h4 className="mb-0 text-center boldFont">
                  {t("birthDetails")}
                </h4>
              </div>
              <form onSubmit={BirthDetails}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label className="control-label">{t("name")}</label>
                        <input
                          className="form-control"
                          placeholder="Full Name"
                          type="text"
                          name="fullName"
                          value={birthDetailsData.fullName}
                          onChange={handleBirthDetailsInputs}
                        />
                        <span
                          className="form-control-feedback"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label className="control-label">{t("gender")}</label>

                        <select
                          onChange={handleBirthDetailsInputs}
                          value={birthDetailsData.gender}
                          name="gender"
                          className="form-control"
                        >
                          <option value="Gender">Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group has-feedback">
                        <label className="control-label">{t("day")}</label>
                        <input
                          className="form-control"
                          placeholder="Day"
                          type="number"
                          name="date"
                          value={birthDetailsData.date}
                          onChange={handleBirthDetailsInputs}
                        />
                        <span
                          className="form-control-feedback"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group has-feedback">
                        <label className="control-label">{t("month")}</label>
                        <input
                          className="form-control"
                          placeholder="Month"
                          type="number"
                          name="month"
                          value={birthDetailsData.month}
                          onChange={handleBirthDetailsInputs}
                        />
                        <span
                          className="form-control-feedback"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group has-feedback">
                        <label className="control-label">{t("year")}</label>
                        <input
                          className="form-control"
                          placeholder="Year"
                          type="number"
                          name="year"
                          value={birthDetailsData.year}
                          onChange={handleBirthDetailsInputs}
                        />
                        <span
                          className="form-control-feedback"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group has-feedback">
                        <label className="control-label">{t("hours")}</label>
                        <input
                          className="form-control"
                          placeholder="hours"
                          type="number"
                          name="hour"
                          value={birthDetailsData.hour}
                          onChange={handleBirthDetailsInputs}
                        />
                        <span
                          className="form-control-feedback"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group has-feedback">
                        <label className="control-label">{t("minutes")}</label>
                        <input
                          className="form-control"
                          placeholder="minutes"
                          type="number"
                          name="min"
                          value={birthDetailsData.min}
                          onChange={handleBirthDetailsInputs}
                        />
                        <span
                          className="form-control-feedback"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group has-feedback">
                        <label className="control-label">{t("seconds")}</label>
                        <input
                          className="form-control"
                          placeholder="seconds"
                          type="number"
                          name="sec"
                          value={birthDetailsData.sec}
                          onChange={handleBirthDetailsInputs}
                        />
                        <span
                          className="form-control-feedback"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group has-feedback">
                        <label className="control-label">{t("place")}</label>

                        <Autocomplete
                          apiKey={apiKey}
                          onPlaceSelected={(add) => {
                            setAddress(add);
                          }}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary submitBtnStyle"
                    >
                      {t("submit")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default BirthDetailsInput;
