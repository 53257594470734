import logo from "../../images/high-logo.jpg";

const Footer = () => {
  return (
    <>
      <footer>
        <section className="as_footer_wrapper p-3">
          <div className="container">
            <h4 className="text-center boldFont" style={{ color: "white" }}>
              Copyright @ 2023 Jyotish Punj. All Right Reserved.
            </h4>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
