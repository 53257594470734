import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

const PaidRoute = ({ disallow = [] }) => {
  let userDetails = localStorage.getItem("token");

  let user = JSON.parse(userDetails);

  let paymentStatus = user?.md_status;
  let flag = false;
  if (paymentStatus === "Renew") {
    flag = true;
  } else if (paymentStatus === "Trial" || paymentStatus === "Paid") {
    flag = false;
  } else {
    flag = true;
  }

  const location = useLocation();

  const navigate = useNavigate();
  const isDisallowed =
    !flag && disallow.some((route) => location.pathname.includes(route));
  useEffect(() => {
    if (isDisallowed) {
      navigate({
        pathname: "/",
        state: { from: location },
      });
    }
  }, [isDisallowed, navigate, location.pathname]);
  return null;
};

export default PaidRoute;
