const dms = (val) => {
  if (val < 0) {
    val = Math.abs(val);
  }

  let hour = parseInt(val, 10);

  let minVal = (val - hour) * 60;
  let min = parseInt(minVal, 10);
  let sec = parseInt((minVal - min) * 60, 10);
  let res;
  res = hour + "-" + min + "-" + sec;
  return res;
};

export default dms;
