// import Header from "./Header1";
import Header from "../Admin/Header";
import Footer from "../Home/Footer";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

const ContactUs = () => {
  const { t } = useTranslation();
  const [contactUsData, setContactUsData] = useState({
    name: "",
    email: "",
    phoneno: "",
    subject: "",
    message: "",
  });

  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;

    setContactUsData({ ...contactUsData, [name]: value });
  };
  const submitDetails = async (e) => {
    e.preventDefault();
    const { name, email, phoneno, subject, message } = contactUsData;
    try {
      const res = await axios({
        method: "post",
        url: "https://jyotishpunj.com/api/jp-astrologer/enquery-insert",
        data: {
          enquery_name: name,
          enquery_email: email,
          enquery_phone: phoneno,
          enquery_subject: subject,
          enquery_message: message,
        },
      });

      if (res.status === 201 && res.data.status === "failed") {
        window.alert(res.data.message);
      }
      if (res.status === 200 && res.data.status == "success") {
        window.alert("Your message was sent, thank you!");
      }
    } catch (e) {}
  };
  return (
    <>
      <Header />
      <section className="ftco-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center mb-4">
              <h2 className="heading-section">Contact Us</h2>
            </div>
          </div>
          <div className="row justify-content-center mb-4">
            <div className="col-md-12">
              <div className="wrapper">
                <div className="row no-gutters">
                  <div className="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
                    <div className="contact-wrap w-100 p-md-5 p-4">
                      <h3 className="mb-4 boldFont">Get in touch</h3>
                      <div id="form-message-warning" className="mb-4"></div>
                      <div id="form-message-success" className="mb-4">
                        Your message was sent, thank you!
                      </div>
                      <form
                        id="contactForm"
                        name="contactForm"
                        className="contactForm"
                        noValidate="novalidate"
                        onSubmit={submitDetails}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label m-2 p-2" htmlFor="name">
                                Full Name
                              </label>
                              <input
                                type="text"
                                className="form-control m-2 p-2"
                                name="name"
                                id="name"
                                placeholder="Name"
                                value={contactUsData.name}
                                onChange={handleInputs}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label m-2 p-2" htmlFor="email">
                                Email Address
                              </label>
                              <input
                                type="email"
                                className="form-control m-2 p-2"
                                name="email"
                                id="email"
                                placeholder="Email"
                                value={contactUsData.email}
                                onChange={handleInputs}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label
                                className="label m-2 p-2"
                                htmlFor="phoneno"
                              >
                                Phone Number
                              </label>
                              <input
                                type="number"
                                className="form-control m-2 p-2"
                                name="phoneno"
                                id="phoneno"
                                placeholder="Phone Number"
                                value={contactUsData.phoneno}
                                onChange={handleInputs}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label
                                className="label m-2 p-2"
                                htmlFor="subject"
                              >
                                Subject
                              </label>
                              <input
                                type="text"
                                className="form-control m-2 p-2"
                                name="subject"
                                id="subject"
                                placeholder="Subject"
                                value={contactUsData.subject}
                                onChange={handleInputs}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea
                                name="message"
                                className="form-control m-2 p-2"
                                id="message"
                                cols="30"
                                rows="4"
                                placeholder="Message"
                                value={contactUsData.message}
                                onChange={handleInputs}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group m-2 p-2">
                              <input
                                type="submit"
                                value="Send Message"
                                className="subscriptionBtnStyle"
                              />
                              <div className="submitting"></div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 d-flex align-items-stretch">
                    <div className="info-wrap backgroundColor w-100 p-md-5 p-4">
                      <h3>Let's get in touch</h3>
                      <p className="mb-4">
                        We're open for any suggestion or just to have a chat
                      </p>
                      <div className="dbox w-100 d-flex align-items-start">
                        <div className="icon d-flex align-items-center justify-content-center m-2">
                          <span className="fa fa-map-marker "></span>
                        </div>
                        <div className="text pl-3">
                          <p>
                            <span>Address:</span> Jyotish Punj Pvt Limited,
                            Professor Colony, Thanesar, Kurukshetra , India
                            136118
                          </p>
                        </div>
                      </div>
                      <div className="dbox w-100 d-flex align-items-center">
                        <div className="icon d-flex align-items-center justify-content-center m-2">
                          <span className="fa fa-phone"></span>
                        </div>
                        <div className="text pl-3">
                          <p>
                            <span>Phone:</span> <a href="#">+91 1744 455 240</a>
                          </p>
                        </div>
                      </div>
                      <div className="dbox w-100 d-flex align-items-center">
                        <div className="icon d-flex align-items-center justify-content-center m-2">
                          <span className="fa fa-paper-plane"></span>
                        </div>
                        <div className="text pl-3">
                          <p>
                            <span>Email:</span>{" "}
                            <a href="mailto:info@yoursite.com">
                              info@jyotishpunj.com
                            </a>
                          </p>
                        </div>
                      </div>
                      {/*<div className="dbox w-100 d-flex align-items-center">*/}
                      {/*  <div className="icon d-flex align-items-center justify-content-center m-2">*/}
                      {/*    <span className="fa fa-globe"></span>*/}
                      {/*  </div>*/}
                      {/*  <div className="text pl-3">*/}
                      {/*    <p>*/}
                      {/*      <span>Website:</span>{" "}*/}
                      {/*      <a href="https://jyotishpunj.com">*/}
                      {/*        https://jyotishpunj.com*/}
                      {/*      </a>*/}
                      {/*    </p>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ContactUs;
