import { useEffect } from "react";

const TodaysPanchang = () => {
  const today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let date = today.getDay();
  const todaysPanchang = async () => {
    try {
      const res = await fetch(`/todays-panchang`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const response = await res.json();

      console.log({ response });

      if (res.status === 400 || !response) {
        window.alert(response.message);
      }
      if (res.status === 200) {
        // let obj = {
        //   ...response,
        //   ...birthDetailsData,
        //   city,
        // };
        //
        // sessionStorage.setItem("details", JSON.stringify(obj));
        // navigate("/details");
      }
    } catch (e) {}
  };

  useEffect(() => {
    todaysPanchang();
  }, []);

  return <></>;
};

export default TodaysPanchang;
