import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../../Home/Footer";
import Sidebar from "../Sidebar";
import AshtakVarg from "./AshtakVarg";
import SarvastakVarga from "./SarvastakVarga";
import LoadingSpinner from "../../LoadingSpinner";

const Varga = () => {
  const { t } = useTranslation();
  let birthDetails = JSON.parse(sessionStorage.getItem("details"));
  const [details, setDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const Varga = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`/vargas`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          year: birthDetails?.year,
          month: birthDetails?.month,
          date: birthDetails?.date,
          hour: birthDetails?.hour,
          min: birthDetails?.min,
          sec: birthDetails?.sec,
          latitude: birthDetails?.latitude,
          longitude: birthDetails?.longitude,
        }),
      });
      const response = await res.json();

      if (res.status === 400 || !response) {
        window.alert(response.message);
        setIsLoading(false);
      }
      if (res.status === 200) {
        setDetails(response);
        setIsLoading(false);
      }
    } catch (e) {}
  };

  useEffect(() => {
    Varga().then((r) => console.log(r));
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  } else if (!isLoading && details) {
    return (
      <>
        <div
          className="wrapper boxed-wrapper"
          style={{ height: "auto", minHeight: "93vh" }}
        >
          <Header />
          <Sidebar />

          <div className="content-wrapper">
            <div className="content-header sty-one">
              <div className="container">
                <section>
                  <div className="tabs-satrt">
                    <div className="left-atab">
                      <ul
                        className="nav nav-tabs start-project-ul"
                        id="myTab"
                        role="tablist"
                      >
                        <li role="presentation" className="nav-item">
                          <a
                            href="#step1"
                            className="nav-link active st1"
                            data-bs-toggle="tab"
                            aria-controls="step1"
                            role="tab"
                            title="Step 1"
                          >
                            Ashtak Varga
                          </a>
                        </li>
                        <li role="presentation" className="nav-item">
                          <a
                            href="#step2"
                            className="nav-link st2"
                            data-bs-toggle="tab"
                            aria-controls="step2"
                            role="tab"
                            title="Step 2"
                          >
                            Sarvastak Varga
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="tabs-pro form-style">
                      <div className="tab-content">
                        <div
                          className="tab-pane  active"
                          role="tabpanel"
                          id="step1"
                        >
                          <AshtakVarg
                            Ashtakvarga={details?.result?.[1]?.Ashtakvarga}
                          />
                        </div>

                        <div className="tab-pane" role="tabpanel" id="step2">
                          <SarvastakVarga
                            Sarvashtakvarga={
                              details?.result?.[0]?.Sarvashtakvarga
                            }
                          />
                        </div>

                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};
export default Varga;
