import { extractAddress } from "../../ExtractAddress";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodeCompare } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../Home/Footer";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Payment = () => {
  const [details, setDetails] = useState();
  const { t } = useTranslation();
  let userDetails = localStorage.getItem("token");

  let user = JSON.parse(userDetails);
  const { state } = useLocation();
  const orderDetails = {
    order_id: Math.round(Math.random(1000000, 9999999) * 1000000),
    currency: "INR",
    amount: Number(state?.price) * 0.18 + Number(state?.price),
    redirect_url: encodeURIComponent(`/payment-response`),
    billing_name: user?.astrologer_name,
    billing_email: user?.astrologer_email,
    billing_phone: user?.astrologer_mobile,
  };

  const Payment = async () => {
    try {
      const res = await fetch(`/payment`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ orderDetails }),
      });

      const response = await res.json();
      console.log({ response });
      if (res.status === 400 || !response) {
        window.alert(response.message);
      }
      if (res.status === 200) {
        setDetails(response?.result);
      }
    } catch (e) {}
  };
  useEffect(() => {
    Payment();
  }, []);

  if (details) {
    return (
      <>
        {/*<div*/}
        {/*  className="wrapper boxed-wrapper"*/}
        {/*  style={{ height: "auto", minHeight: "90vh" }}*/}
        {/*>*/}
        <Header />
        {/*<Sidebar />*/}
        {/*<div className="content-wrapper">*/}
        {/*  <div className="content-header sty-one">*/}
        {/*    <h1> {t("payment")}</h1>*/}
        {/*    <ol className="breadcrumb">*/}
        {/*      <li>*/}
        {/*        <a href="#">Home</a>*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        <i className="fa fa-angle-right"></i> {t("payment")}*/}
        {/*      </li>*/}
        {/*    </ol>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="content-wrapper">*/}
        {/*  <div className="row m-2">*/}
        {/*<div className="col-lg-12">*/}
        <div className="content-header sty-one">
          <div className="content">
            <div className="card mt-4 mb-4">
              <div className="card-header bg-blue">
                <h5 className="mb-0 text-center boldFont">{t("payment")}</h5>
              </div>
              <div className="card-body">
                <form
                  method="post"
                  name="redirect"
                  action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
                >
                  <input
                    type="hidden"
                    id="encRequest"
                    name="encRequest"
                    value={details}
                  />
                  <input
                    type="hidden"
                    name="access_code"
                    id="access_code"
                    value="AVCH20KB47BB88HCBB"
                  />
                  <div className="form-body">
                    <div className="form-group row">
                      <label className="control-label text-right col-md-3">
                        Name
                      </label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          name="astrologer_name"
                          value={user?.astrologer_name}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="control-label text-right col-md-3">
                        Mobile No
                      </label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          name="astrologer_mobile"
                          value={user?.astrologer_mobile}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="control-label text-right col-md-3">
                        Email
                      </label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          name="astrologer_email"
                          value={user?.astrologer_email}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="control-label text-right col-md-3">
                        Address
                      </label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          name="billing_address"
                          value={user?.astrologer_address}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="control-label text-right col-md-3">
                        Amount
                      </label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          name="amount"
                          value={state?.price}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="control-label text-right col-md-3">
                        GST Amount(18%){" "}
                      </label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          name="gstamount"
                          value={Number(state?.price) * 0.18}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="control-label text-right col-md-3">
                        Total Amount
                      </label>
                      <div className="col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          name="amount"
                          value={
                            Number(state?.price) * 0.18 + Number(state?.price)
                          }
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <input type="hidden" name="language" value="EN" />

                  <input type="hidden" name="billing_city" value="" />
                  <input type="hidden" name="billing_state" value="" />
                  <input type="hidden" name="billing_zip" value="" />
                  <input type="hidden" name="billing_country" value="" />
                  <input type="hidden" name="delivery_name" value="" />
                  <input type="hidden" name="delivery_address" value="" />
                  <input type="hidden" name="delivery_city" value="" />
                  <input type="hidden" name="delivery_state" value="" />
                  <input type="hidden" name="delivery_zip" value="" />
                  <input type="hidden" name="delivery_country" value="" />
                  <input type="hidden" name="delivery_tel" value="" />
                  <input type="hidden" name="merchant_param1" value="" />
                  <input type="hidden" name="merchant_param2" value="" />
                  <input type="hidden" name="merchant_param3" value="" />
                  <input type="hidden" name="merchant_param4" value="" />
                  <input type="hidden" name="merchant_param5" value="" />
                  <input type="hidden" name="payment_option" value="" />
                  <div className="col-md-12 text-center">
                    <input
                      type="submit"
                      value="Pay Now"
                      className="btn btn-primary submitBtnStyle"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/*</div>*/}
        {/*</div>*/}
        <Footer />
      </>
    );
  }
};
export default Payment;
