import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

let house = (degrees) => {
  return Math.floor(degrees / 30) + 1;
};
function sliceData(data) {
  let sunAshtakVarga = [];
  let array = [];

  for (let k = 0; k < data.length; k = k + 8) {
    array = data.slice(k, k + 8);

    let total = 0;
    array.map((value, ind) => {
      let val = Object.entries(Object.entries(value)?.[0]?.[1])?.[0]?.[1];
      total = total + val;
    });
    array = [...array, { Total: { Total: total } }];
    sunAshtakVarga.push(array);

    array = [];
  }
  return sunAshtakVarga;
}
const AshtakVarg = (props) => {
  const { t } = useTranslation();

  let ashtakVargaSun = sliceData(props?.Ashtakvarga?.[0]?.Sun);
  let ashtakVargaMoon = sliceData(props?.Ashtakvarga?.[1]?.Moon);
  let ashtakVargaMars = sliceData(props?.Ashtakvarga?.[2]?.Mars);
  let ashtakVargaMercury = sliceData(props?.Ashtakvarga?.[3]?.Mercury);
  let ashtakVargaJupiter = sliceData(props?.Ashtakvarga?.[4]?.Jupiter);
  let ashtakVargaVenus = sliceData(props?.Ashtakvarga?.[5]?.Venus);
  let ashtakVargaSaturn = sliceData(props?.Ashtakvarga?.[6]?.Saturn);

  return (
    <>
      <div className="content">
        <div className="card card-shadow">
          <div className="card-header bg-blue">
            <h5 className="mb-0 text-center boldFont">{t("ashtakVarg")}</h5>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                {/*<div className="content">*/}
                <div className="card">
                  <div className="card-body">
                    <h4 className="text-black">{t("Su")}</h4>

                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">{t("graha")}</th>
                            <th scope="col">{t("Su")}</th>
                            <th scope="col">{t("Mo")}</th>
                            <th scope="col">{t("Ma")}</th>
                            <th scope="col">{t("Me")}</th>
                            <th scope="col">{t("Ju")}</th>
                            <th scope="col">{t("Ve")}</th>
                            <th scope="col">{t("Sa")}</th>
                            <th scope="col">{t("ascendant")}</th>

                            <th scope="col">{t("total")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ashtakVargaSun?.map((val, ind) => {
                            return (
                              <tr>
                                <th scope="row">
                                  {t(`${Object.keys(val[0]?.Sun)}`)}
                                </th>

                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[0])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[1])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[2])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[3])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[4])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[5])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[6])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[7])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>

                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[8])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                              </tr>
                            );
                          })}
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/*</div>*/}
              </div>
              <div className="col-6">
                {/*<div className="content">*/}
                <div className="card">
                  <div className="card-body">
                    <h4 className="text-black">{t("Mo")}</h4>

                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">{t("graha")}</th>
                            <th scope="col">{t("Su")}</th>
                            <th scope="col">{t("Mo")}</th>
                            <th scope="col">{t("Ma")}</th>
                            <th scope="col">{t("Me")}</th>
                            <th scope="col">{t("Ju")}</th>
                            <th scope="col">{t("Ve")}</th>
                            <th scope="col">{t("Sa")}</th>
                            <th scope="col">{t("ascendant")}</th>

                            <th scope="col">{t("total")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ashtakVargaMoon?.map((val, ind) => {
                            return (
                              <tr>
                                <th scope="row">
                                  {t(`${Object.keys(val[0]?.Sun)}`)}
                                </th>

                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[0])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[1])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[2])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[3])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[4])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[5])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[6])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[7])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>

                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[8])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                              </tr>
                            );
                          })}
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/*</div>*/}
              </div>
              <div className="col-6 pt-4">
                <div className="card">
                  <div className="card-body">
                    <h4 className="text-black">{t("Ma")}</h4>

                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">{t("graha")}</th>
                            <th scope="col">{t("Su")}</th>
                            <th scope="col">{t("Mo")}</th>
                            <th scope="col">{t("Ma")}</th>
                            <th scope="col">{t("Me")}</th>
                            <th scope="col">{t("Ju")}</th>
                            <th scope="col">{t("Ve")}</th>
                            <th scope="col">{t("Sa")}</th>
                            <th scope="col">{t("ascendant")}</th>

                            <th scope="col">{t("total")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ashtakVargaMars?.map((val, ind) => {
                            return (
                              <tr>
                                <th scope="row">
                                  {t(`${Object.keys(val[0]?.Sun)}`)}
                                </th>

                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[0])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[1])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[2])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[3])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[4])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[5])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[6])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[7])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>

                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[8])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                              </tr>
                            );
                          })}
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6 pt-4">
                <div className="card">
                  <div className="card-body">
                    <h4 className="text-black">{t("Me")}</h4>

                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">{t("graha")}</th>
                            <th scope="col">{t("Su")}</th>
                            <th scope="col">{t("Mo")}</th>
                            <th scope="col">{t("Ma")}</th>
                            <th scope="col">{t("Me")}</th>
                            <th scope="col">{t("Ju")}</th>
                            <th scope="col">{t("Ve")}</th>
                            <th scope="col">{t("Sa")}</th>
                            <th scope="col">{t("ascendant")}</th>

                            <th scope="col">{t("total")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ashtakVargaMercury?.map((val, ind) => {
                            return (
                              <tr>
                                <th scope="row">
                                  {t(`${Object.keys(val[0]?.Sun)}`)}
                                </th>

                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[0])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[1])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[2])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[3])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[4])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[5])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[6])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[7])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>

                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[8])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                              </tr>
                            );
                          })}
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6 pt-4">
                <div className="card">
                  <div className="card-body">
                    <h4 className="text-black">{t("Ju")}</h4>

                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">{t("graha")}</th>
                            <th scope="col">{t("Su")}</th>
                            <th scope="col">{t("Mo")}</th>
                            <th scope="col">{t("Ma")}</th>
                            <th scope="col">{t("Me")}</th>
                            <th scope="col">{t("Ju")}</th>
                            <th scope="col">{t("Ve")}</th>
                            <th scope="col">{t("Sa")}</th>
                            <th scope="col">{t("ascendant")}</th>

                            <th scope="col">{t("total")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ashtakVargaJupiter?.map((val, ind) => {
                            return (
                              <tr>
                                <th scope="row">
                                  {t(`${Object.keys(val[0]?.Sun)}`)}
                                </th>

                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[0])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[1])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[2])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[3])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[4])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[5])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[6])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[7])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>

                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[8])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                              </tr>
                            );
                          })}
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6 pt-4">
                <div className="card">
                  <div className="card-body">
                    <h4 className="text-black">{t("Ve")}</h4>

                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">{t("graha")}</th>
                            <th scope="col">{t("Su")}</th>
                            <th scope="col">{t("Mo")}</th>
                            <th scope="col">{t("Ma")}</th>
                            <th scope="col">{t("Me")}</th>
                            <th scope="col">{t("Ju")}</th>
                            <th scope="col">{t("Ve")}</th>
                            <th scope="col">{t("Sa")}</th>
                            <th scope="col">{t("ascendant")}</th>

                            <th scope="col">{t("total")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ashtakVargaVenus?.map((val, ind) => {
                            return (
                              <tr>
                                <th scope="row">
                                  {t(`${Object.keys(val[0]?.Sun)}`)}
                                </th>

                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[0])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[1])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[2])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[3])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[4])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[5])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[6])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[7])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>

                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[8])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                              </tr>
                            );
                          })}
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 pt-4">
                <div className="card">
                  <div className="card-body">
                    <h4 className="text-black">{t("Sa")}</h4>

                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">{t("graha")}</th>
                            <th scope="col">{t("Su")}</th>
                            <th scope="col">{t("Mo")}</th>
                            <th scope="col">{t("Ma")}</th>
                            <th scope="col">{t("Me")}</th>
                            <th scope="col">{t("Ju")}</th>
                            <th scope="col">{t("Ve")}</th>
                            <th scope="col">{t("Sa")}</th>
                            <th scope="col">{t("ascendant")}</th>

                            <th scope="col">{t("total")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ashtakVargaSaturn?.map((val, ind) => {
                            return (
                              <tr>
                                <th scope="row">
                                  {t(`${Object.keys(val[0]?.Sun)}`)}
                                </th>

                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[0])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[1])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[2])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[3])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[4])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[5])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[6])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[7])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>

                                <td>
                                  {
                                    Object.entries(
                                      Object.entries(val[8])?.[0]?.[1]
                                    )?.[0]?.[1]
                                  }
                                </td>
                              </tr>
                            );
                          })}
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AshtakVarg;
