import { ThreeDots } from "react-loader-spinner";

export default function LoadingSpinner() {
  return (
    <>
      <div className="load-spinner">
        <ThreeDots
          height="100"
          width="100"
          color="#9a1509"
          ariaLabel="loading"
          wrapperStyle
          wrapperClass
          visible={true}
        />
      </div>
    </>
  );
}
