import Header from "./Header";
import Footer from "../Home/Footer";
import Sidebar from "./Sidebar";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import chart from "../../images/fontawesome/chart.png";
import planet from "../../images/fontawesome/planet.png";
import friend from "../../images/fontawesome/friends.png";
import moon from "../../images/fontawesome/moon.png";
import downloadpdf from "../../images/fontawesome/downloadpdf.png";
import {
  faAtom,
  faDownload,
  faCodeCompare,
} from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../LoadingSpinner";

const UserDashboard = () => {
  const { t } = useTranslation();
  const [details, setDetails] = useState();
  let birthDetails = JSON.parse(sessionStorage.getItem("details"));
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  let userDetails = localStorage.getItem("token");
  const user = JSON.parse(userDetails);
  console.log({ user });
  const DownloadKundali = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`/download-pdf`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: birthDetails?.fullName?.trim(),
          gender: birthDetails?.gender,

          year: birthDetails?.year?.toString(),
          month: birthDetails?.month?.toString(),
          date: birthDetails?.date?.toString(),
          hour: birthDetails?.hour?.toString(),
          min: birthDetails?.min?.toString(),
          sec: birthDetails?.sec?.toString(),
          latitude: birthDetails?.latitude,
          longitude: birthDetails?.longitude,
          place: birthDetails?.city,
          language: "en",
          astrologerName: user?.astrologer_name,
          astrologerEmail: user?.astrologer_email,
          astrologerMobile: user?.astrologer_mobile,
          astrologerAddress: user?.astrologer_address,
        }),
      });
      const response = await res.json();

      if (res.status === 400 || !response) {
        window.alert(response.message);
        setIsLoading(false);
      }
      if (res.status === 200) {
        // fileDownload(
        //   `/kundali-pdf/${response?.fileName}`,
        //   "kundali.pdf"
        // );
        //setDetails(response);
        setIsLoading(false);
        navigate("/download-kundali", {
          state: { fileName: response?.fileName },
        });
      }
    } catch (e) {}
  };
  if (isLoading) {
    return (
      <>
        <LoadingSpinner />
      </>
    );
  }
  return (
    <>
      <div
        className="wrapper boxed-wrapper"
        style={{ height: "auto", minHeight: "93vh" }}
      >
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header sty-one">
            <h1>
              {" "}
              {birthDetails?.fullName} {t("dashboard")}
            </h1>
            <ol className="breadcrumb">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <i className="fa fa-angle-right"></i> {birthDetails?.fullName}{" "}
                {t("dashboard")}
              </li>
            </ol>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="row p-3">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3">
              <a href="basic-charts">
                <div className="card m-3 dashboardStyle ">
                  <div className="card-body m-3">
                    {" "}
                    <div className="info-widget-text row">
                      <div className="center-align m-1">
                        <img src={chart} alt="chart" className="m-1" />
                        {/*<i className="fa fa-pie-chart"></i>*/}
                      </div>
                      <div className="center-align">
                        <span>Basic Charts</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3">
              <a href="vargas">
                <div className="card m-3 dashboardStyle">
                  <div className="card-body m-3">
                    <div className="info-widget-text row">
                      <div className="center-align m-1">
                        <img src={moon} alt="chart" className="m-1" />
                        {/*<i className="fa fa-star"></i>*/}
                      </div>
                      <div className="center-align">
                        <span>Vargas </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3">
              <a href="friendship-table">
                <div className="card m-3 dashboardStyle ">
                  <div className="card-body m-3">
                    <div className="info-widget-text row">
                      <div className="center-align m-1">
                        <img src={friend} alt="chart" className="m-1" />
                        {/*<i className="fa fa-handshake-o"></i>*/}
                      </div>
                      <div className="center-align">
                        <span>Grah Maitri </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3">
              <a href="shodasvarga-charts">
                <div className="card m-3 dashboardStyle ">
                  <div className="card-body m-3">
                    <div className="info-widget-text row">
                      <div className="center-align m-1">
                        <img src={chart} alt="chart" className="m-1" />
                      </div>
                      <div className="center-align">
                        <span>Shodas Varga Charts </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3">
              <a href="avakahada-details">
                <div className="card m-3 dashboardStyle ">
                  <div className="card-body m-3">
                    <div className="info-widget-text row">
                      <div className="center-align m-1">
                        <img src={planet} alt="chart" className="m-1" />
                        {/*<i className="fa fa-pie-chart"></i>*/}
                      </div>
                      <div className="center-align">
                        <span>Avkahada Details </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3">
              <a href="dasha">
                <div className="card m-3 dashboardStyle ">
                  <div className="card-body m-3">
                    <div className="info-widget-text row">
                      <div className="center-align m-1">
                        <FontAwesomeIcon icon={faAtom} size="2x" />{" "}
                      </div>
                      <div className="center-align">
                        <span>Dasha </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            {/*<div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-b-3">*/}
            {/*  <a href="matchkundali">*/}
            {/*    <div className="card m-3 dashboardStyle ">*/}
            {/*      <div className="card-body m-3">*/}
            {/*        <div className="info-widget-text row">*/}
            {/*          <div className="center-align m-1">*/}
            {/*            <FontAwesomeIcon icon={faCodeCompare} />{" "}*/}
            {/*          </div>*/}
            {/*          <div className="center-align">*/}
            {/*            <span>Match Kundali </span>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </a>*/}
            {/*</div>*/}

            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 mb-3">
              <a>
                <div
                  className="card m-3 dashboardStyle"
                  style={{ cursor: "default" }}
                >
                  <div className="card-body m-3">
                    <div
                      className="info-widget-text row"
                      onClick={() => DownloadKundali()}
                    >
                      <div className="center-align m-1">
                        <img src={downloadpdf} alt="chart" className="m-1" />
                        {/*<FontAwesomeIcon icon={faDownload} />{" "}*/}
                      </div>
                      <div className="center-align">
                        <span>Download Kundali </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserDashboard;
