import logo from "../images/high-logo.jpg";
// import Header from "./Home/Header1";
import Header from "./Admin/Header";
import Footer from "./Home/Footer";
import bg from "../images/background.png";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else delete axios.defaults.headers.common["Authorization"];
};

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState({
    mobileNo: "",
  });

  const [otp, setOtp] = useState({
    otp_no: "",
  });

  const [details, setDetails] = useState();
  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;

    setMobile({ ...mobile, [name]: value });
  };

  const handleOtp = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setOtp({ ...otp, [name]: value });
  };

  const Login = async () => {
    setIsLoading(true);
    try {
      const { mobileNo } = mobile;

      const res = await axios({
        method: "post",
        url: "https://jyotishpunj.com/api/jp-astrologer/send-otp",
        data: {
          astrologer_mobile: +mobileNo,
          astrologer_countrycode: 91,
        },
      });

      if (res.status === 201 && res.data.status === "failed") {
        window.alert(res.data.message);
        setIsLoading(false);
      }
      if (res.status === 200) {
        setDetails("Success");
        setIsLoading(false);
      }
    } catch (e) {}
  };

  // const Subscription = async (id) => {
  //   try {
  //     const res = await axios({
  //       method: "post",
  //       url: "https://jyotishpunj.com/api/jp-astrologer/membership-details",
  //       data: {
  //         astrologer_id: id,
  //       },
  //     });
  //
  //     if (res.status === 201 && res.data.status === "failed") {
  //       window.alert(res.data.message);
  //     }
  //     if (res.status === 200) {
  //       let token = res?.data?.astrologerDataGet;
  //       console.log({ token });
  //       localStorage.setItem("token", JSON.stringify(token));
  //       // setAuthToken(token);
  //       navigate("/admin-dashboard");
  //     }
  //   } catch (e) {}
  // };
  const VerifyOtp = async (e) => {
    e.preventDefault();
    try {
      const { mobileNo } = mobile;
      const { otp_no } = otp;

      const res = await axios({
        method: "post",
        url: "https://jyotishpunj.com/api/jp-astrologer/otp-checked",
        data: {
          astrologer_mobile: +mobileNo,
          otp_no: +otp_no,
        },
      });

      if (res.status === 201 && res.data.status === "failed") {
        window.alert(res.data.message);
      }
      if (res.status === 200) {
        let token = res?.data?.astrologerDataGet;
        let memberShipData = res?.data?.membershipDataGet;
        let obj = { ...token, ...memberShipData };
        console.log({ obj });
        localStorage.setItem("token", JSON.stringify(obj));
        // setAuthToken(token);
        if (res?.data?.membershipDataGet?.md_status === "Renew") {
          navigate("/plan");
        } else {
          navigate("/admin-dashboard");
        }
      }
    } catch (e) {}
  };
  if (isLoading) {
    return <LoadingSpinner />;
  } else if (!isLoading) {
    return (
      <>
        <div
          style={{
            backgroundImage: `url(${bg})`,
          }}
        >
          <Header />
          {!details ? (
            <div
              className="login-box"
              style={{ height: "auto", minHeight: "70vh" }}
            >
              <div className="login-box-body">
                <center className="mb-5">
                  <img
                    src={logo}
                    className="user-image"
                    alt="User Image"
                    style={{ width: "100px" }}
                  />
                </center>

                <form method="post" onSubmit={Login}>
                  <div className="form-group has-feedback">
                    <input
                      type="number"
                      className="form-control sty1"
                      name="mobileNo"
                      placeholder="Phone Number"
                      onChange={handleInputs}
                      value={mobile.mobileNo}
                    />
                  </div>

                  <div>
                    <div className="col-xs-8 mt-2">
                      <div className="checkbox icheck">
                        <a
                          href="#"
                          className="pull-right mb-3"
                          style={{ color: "#9a1509" }}
                        >
                          <i className="fa fa-lock"></i> Forgot pwd?
                        </a>{" "}
                      </div>
                    </div>

                    <div className="col-xs-4 mt-3">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-flat"
                        style={{ fontWeight: "bold" }}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </form>

                <div className="mt-3">
                  Don't have an account?{" "}
                  <a
                    href="https://jyotishpunj.com/astrologer/register"
                    className="text-center"
                    style={{ color: "#9a1509" }}
                  >
                    Register Now{" "}
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="login-box" style={{ minHeight: "70vh" }}>
              <div className="login-box-body">
                <center className="mb-5">
                  <img
                    src={logo}
                    className="user-image"
                    alt="User Image"
                    style={{ width: "100px" }}
                  />
                </center>

                <form method="post" onSubmit={VerifyOtp}>
                  <div className="form-group has-feedback">
                    <input
                      type="number"
                      className="form-control sty1"
                      name="otp_no"
                      placeholder="Enter Otp"
                      onChange={handleOtp}
                      value={otp.otp_no}
                    />
                  </div>

                  <div>
                    <div className="col-xs-4 mt-5">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block btn-flat"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
          <Footer />
        </div>
      </>
    );
  }
};

export default Login;
