import Header from "../Header";
import Footer from "../../Home/Footer";
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import price from "../../../utils/PriceDetails";

const PaymentResponse = () => {
  const [orderStatus, setOrderStatus] = useState("Success");
  const { t } = useTranslation();
  let userDetails = localStorage.getItem("token");
  let user = JSON.parse(userDetails);
  const navigate = useNavigate();
  if (orderStatus === "Success") {
    let Subscription = async () => {
      try {
        const res = await axios({
          method: "post",
          url: "https://jyotishpunj.com/api/jp-astrologer/membership-plan",
          data: {
            astrologer_id: user?.astrologer_id,
            subscription_name: "Software plan",
            subscription_price: 5000,
            subscription_paymentamt: 5000,
            subscription_paystatus: "Success",
            subscription_transactionid: "45urfb53693",
          },
        });

        if (res.status === 201 && res.data.status === "failed") {
          window.alert(res.data.message);
        }
        if (res.status === 200) {
          window.alert(res.data.message);
          navigate("/admin-dashboard");
        }
      } catch (e) {}
    };
  }
  if (orderStatus === "Success") {
    return (
      <>
        <Header />

        <div
          className="wrapper boxed-wrapper"
          style={{ height: "auto", minHeight: "100%" }}
        >
          <div className="content-header sty-one">
            <div className="content">
              <div className="card mt-4">
                <div className="card-header bg-blue">
                  <h4 className="mb-0 text-center boldFont">
                    {t("paymentStatus")}
                  </h4>
                </div>
                <p className="paymentStatusSuccess text-center">
                  "Thank you for shopping with us.We will keep you posted <br />{" "}
                  regarding the status of your order through e-mail"
                </p>
                <div className="payment text-center">
                  <div className="dv">
                    <p>
                      <strong> Order ID </strong> : <span>2345167</span>
                    </p>
                  </div>

                  <div className="dv">
                    <p>
                      <strong>Order Amount</strong> :<span>Rs 590</span>
                    </p>
                  </div>

                  <div className="dv">
                    <p>
                      <strong>Transaction ID</strong> :<span>56883</span>
                    </p>
                  </div>

                  <div className="dv">
                    <p>
                      <strong>Transaction Date</strong> :<span>06/07/2023</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  } else if (orderStatus === "Aborted") {
    return (
      <>
        <Header />
        <div
          className="wrapper boxed-wrapper"
          style={{ height: "auto", minHeight: "100%" }}
        >
          <div className="content-header sty-one">
            <div className="content">
              <div className="card mt-4">
                <div className="card-header bg-blue">
                  <h4 className="mb-0 text-center boldFont">
                    {t("paymentStatus")}
                  </h4>
                </div>

                <p className="paymentStatus text-center">
                  "Thank you for shopping with us.We will <br />
                  keep you posted regarding the status of your order through
                  e-mail"
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  } else if (orderStatus === "Failure") {
    return (
      <>
        <Header />
        <div
          className="wrapper boxed-wrapper"
          style={{ height: "auto", minHeight: "100%" }}
        >
          <div className="content-header sty-one">
            <div className="content">
              <div className="card mt-4">
                <div className="card-header bg-blue">
                  <h4 className="mb-0 text-center boldFont">
                    {t("paymentStatus")}
                  </h4>
                </div>

                <p className="paymentStatus text-center">
                  "Thank you for shopping with us.
                  <br />
                  However,the transaction has been declined."
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Header />
        <div
          className="wrapper boxed-wrapper"
          style={{ height: "auto", minHeight: "100%" }}
        >
          <div className="content-header sty-one">
            <div className="content">
              <div className="card mt-4">
                <div className="card-header bg-blue">
                  <h4 className="mb-0 text-center boldFont">
                    {t("paymentStatus")}
                  </h4>
                </div>

                <p className="paymentStatus text-center">
                  "Security Error.
                  <br /> Illegal access detected"
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};
export default PaymentResponse;
