// import Header from "../Admin/Header";
import Header from "../Admin/Header";
import Banner from "./Banner";
import WhyChooseUs from "./WhyChooseUs";
import Footer from "./Footer";

const Home = () => {
  return (
    <>
      <Header />

      <Banner />

      <WhyChooseUs />

      <Footer />
    </>
  );
};

export default Home;
