import { useTranslation } from "react-i18next";

const MahaDasha = (props) => {
  const { t } = useTranslation();
  console.log(props);
  return (
    <>
      <div className="content">
        <div className="center-align vd-sub-hdg br-b">
          <span style={{ fontWeight: "bold", margin: "5px" }}>
            Balance Of Dasha
          </span>{" "}
          :{" "}
          <span style={{ margin: "5px" }}>{props?.mahaDasha?.dasaBalance}</span>
        </div>
        <table
          id="kundlibasicdetailsWebId"
          width="100%"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          className="kundli_basic_details"
        >
          <tbody>
            <tr>
              <th scope="col" colSpan="2">
                {" "}
                <i className="fa fa-file" style={{ padding: "10px" }}></i>
                {t("mahaDasha")}
              </th>
            </tr>

            {props?.mahaDasha?.vimDasa?.map((val) => {
              return (
                <tr>
                  <td scope="col">{t(`${Object.keys(val)}`)}</td>
                  <td scope="col">{t(`${Object.values(val)}`)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default MahaDasha;
