const WhyChooseUs = () => {
  return (
    <>
      <section
        className="as_whychoose_wrapper as_padderTop20 as_padderBottom40 bg-white"
        style={{ color: "black" }}
      >
        <div className="container">
          <div className="col-lg-12 col-md-12 text-center">
            <h1 className="as_heading headii">Why Choose Us</h1>
            <p className="as_font20 as_margin0 as_padderTop20 as_padderBottom60">
              Jyotish Punj is India's Most Trusted Astrology Platform having
              large pool of Expert Astrologers.
            </p>
          </div>
          <div className="row as_verticle_center">
            <div className="col-lg-12 col-md-12">
              <div className="row m-4">
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  <div className="as_whychoose_box text-center">
                    <span className="as_number">
                      <span style={{ color: "white" }}>
                        <span data-from="0" data-to="512" data-speed="5000">
                          512
                        </span>
                        +
                      </span>
                    </span>
                    <h4>Qualified Astrologers</h4>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  <div className="as_whychoose_box text-center">
                    <span className="as_number">
                      <span style={{ color: "white" }}>
                        <span data-from="0" data-to="2300" data-speed="5000">
                          2300
                        </span>
                        +
                      </span>
                    </span>
                    <h4>Total Chat/Call Minutes</h4>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  <div className="as_whychoose_box text-center">
                    <span className="as_number">
                      <span style={{ color: "white" }}>
                        <span data-from="0" data-to="1100" data-speed="5000">
                          1100
                        </span>
                        +
                      </span>
                    </span>
                    <h4>Trusted Clients</h4>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                  <div className="as_whychoose_box text-center">
                    <span className="as_number">
                      <span style={{ color: "white" }}>
                        <span data-from="0" data-to="15" data-speed="5000">
                          15
                        </span>
                        +
                      </span>
                    </span>
                    <h4>Years Avg. Experience</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChooseUs;
