import { useTranslation } from "react-i18next";
import React from "react";

const FiveFoldFriendShip = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="content">
        <div className="card mt-4">
          <div className="card-header bg-blue">
            <h5 className="mb-0 text-center boldFont">
              {t("fiveFoldFriendship")}
            </h5>
          </div>
          {/*<div className="card">*/}
          {/*  <div className="card-body">*/}
          {/*<h4 className="text-black">{t("fiveFoldFriendship")}</h4>*/}

          <div className="table-responsive m-2">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">{t("graha")}</th>

                  {Object.keys(props.fiveFoldFriendShip)?.map((val, index) => {
                    return (
                      <>
                        <th scope="col">{t(`${val}`)}</th>
                      </>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {Object.entries(props.fiveFoldFriendShip)?.map((val, ind) => {
                  return (
                    <tr>
                      <th scope="row"> {t(`${val[0]}`)}</th>
                      {val?.[1]?.map((v, i) => {
                        return (
                          <>
                            <td>{t(`${Object.entries(v)?.[0]?.[1]}`)}</td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
};
export default FiveFoldFriendShip;
