import { useTranslation } from "react-i18next";

const SookshamDasha = (props) => {
  const { t } = useTranslation();
  let data = props.sookshamaDasha;
  return (
    <>
      <div className="content">
        <div className="card card-shadow">
          <div className="card-header bg-blue">
            <h5 className="mb-0 text-center boldFont">{t("sookshamaDasha")}</h5>
          </div>

          <div className="row m-3">
            <div className="col-4">
              {data?.slice(0, 234)?.map((val, index) => {
                return (
                  <>
                    <h5>{Object.keys(val)}</h5>
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead></thead>
                        <tbody>
                          {Object.values(val)?.map((value, ind) => {
                            return value?.map((planet, no) => {
                              return (
                                <>
                                  <tr>
                                    <th scope="row">
                                      {t(`${Object.keys(planet)}`)}
                                    </th>
                                    <td>{Object.values(planet)}</td>
                                  </tr>
                                </>
                              );
                            });
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                );
              })}
            </div>

            <div className="col-4">
              {data?.slice(234, 468)?.map((val, index) => {
                return (
                  <>
                    <h5>{Object.keys(val)}</h5>
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead></thead>
                        <tbody>
                          {Object.values(val)?.map((value, ind) => {
                            return value?.map((planet, no) => {
                              return (
                                <>
                                  <tr>
                                    <th scope="row">
                                      {t(`${Object.keys(planet)}`)}
                                    </th>
                                    <td>{Object.values(planet)}</td>
                                  </tr>
                                </>
                              );
                            });
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                );
              })}
            </div>

            <div className="col-4">
              {data?.slice(468, 702)?.map((val, index) => {
                return (
                  <>
                    <h5>{Object.keys(val)}</h5>
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead></thead>
                        <tbody>
                          {Object.values(val)?.map((value, ind) => {
                            return value?.map((planet, no) => {
                              return (
                                <>
                                  <tr>
                                    <th scope="row">
                                      {t(`${Object.keys(planet)}`)}
                                    </th>
                                    <td>{Object.values(planet)}</td>
                                  </tr>
                                </>
                              );
                            });
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SookshamDasha;
