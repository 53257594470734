import { useTranslation } from "react-i18next";

const AntarDasha = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="content">
        <div className="card card-shadow">
          <div className="card-header bg-blue">
            <h5 className="mb-0 text-center boldFont">{t("antarDasha")}</h5>
          </div>

          <div className="row m-3">
            <div className="col-6">
              {props.antarDasha?.slice(0, 5)?.map((val, index) => {
                return (
                  <>
                    <h5>{t(`${Object.keys(val)}`)}</h5>
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead></thead>
                        <tbody>
                          {Object.values(val)?.map((value, ind) => {
                            return value?.map((planet, no) => {
                              return (
                                <>
                                  <tr>
                                    <th scope="row">
                                      {t(`${Object.keys(planet)}`)}
                                    </th>
                                    <td>{Object.values(planet)}</td>
                                  </tr>
                                </>
                              );
                            });
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="col-6">
              {props.antarDasha?.slice(5, 10)?.map((val, index) => {
                return (
                  <>
                    <h5>{t(`${Object.keys(val)}`)}</h5>
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead></thead>
                        <tbody>
                          {Object.values(val)?.map((value, ind) => {
                            return value?.map((planet, no) => {
                              return (
                                <>
                                  <tr>
                                    <th scope="row">
                                      {t(`${Object.keys(planet)}`)}
                                    </th>
                                    <td>{Object.values(planet)}</td>
                                  </tr>
                                </>
                              );
                            });
                          })}
                        </tbody>
                      </table>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AntarDasha;
