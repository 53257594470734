// import Header from "../../Home/Header1";
import Header from "../../Admin/Header";

import Footer from "../../Home/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import price from "../../../utils/PriceDetails";
//const nodeCCAvenue = require("node-ccavenue");const nodeCCAvenue = require("node-ccavenue");

const Plan = () => {
  const user = JSON.parse(localStorage.getItem("token"));

  const navigate = useNavigate();
  const PaymentPage = (price, plan) => {
    navigate("/payment", { state: { price: price, plan: plan } });
  };

  let Subscription = async () => {
    if (!user) {
      window.alert("Please Login first");
    } else {
      try {
        const res = await axios({
          method: "post",
          url: "https://jyotishpunj.com/api/jp-astrologer/membership-plan",
          data: {
            astrologer_id: user?.astrologer_id,
            subscription_name: "Software plan",
            subscription_price: 5000,
            subscription_paymentamt: 5000,
            subscription_paystatus: "Success",
            subscription_transactionid: "45urfb53693",
          },
        });

        if (res.status === 201 && res.data.status === "failed") {
          window.alert(res.data.message);
        }
        if (res.status === 200) {
          window.alert(res.data.message);
          navigate("/admin-dashboard");
        }
      } catch (e) {}
    }
  };
  return (
    <>
      <Header />
      <div
        id="generic_price_table"
        style={{ height: "auto", minHeight: "85vh" }}
      >
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="price-heading clearfix">
                  <h1>Choose Your Plan</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="generic_content clearfix mb-5">
                  <div className="generic_head_price clearfix">
                    <div className="generic_head_content clearfix">
                      <div className="head_bg"></div>
                      <div className="head">
                        <span>{price[0]?.plan}</span>
                      </div>
                    </div>

                    <div className="generic_price_tag clearfix">
                      <span className="price">
                        <span className="sign">Rs</span>
                        <span className="currency">{price[0]?.price}</span>

                        <span className="month">/MONTH</span>
                      </span>
                    </div>
                  </div>

                  <div className="generic_feature_list">
                    <ul></ul>
                  </div>

                  <div
                    className="generic_price_btn clearfix"
                    // onClick={() => PaymentPage(price[0]?.price, price[0]?.plan)}
                  >
                    BUY
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="generic_content active clearfix">
                  <div className="generic_head_price clearfix">
                    <div className="generic_head_content clearfix">
                      <div className="head_bg"></div>
                      <div className="head">
                        <span>{price[1]?.plan}</span>
                      </div>
                    </div>

                    <div className="generic_price_tag clearfix">
                      <span className="price">
                        <span className="sign">Rs</span>
                        <span className="currency">{price[1]?.price}</span>

                        <span className="month">/YEAR</span>
                      </span>
                    </div>
                  </div>

                  <div
                    className="generic_price_btn clearfix"
                    // onClick={() => Subscription()}

                    // onClick={() => PaymentPage(price[1]?.price,price[1]?.plan)}
                  >
                    {/*<a href="#">*/}
                    BUY
                    {/*</a>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Plan;
