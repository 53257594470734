import l from "../../images/high-logo.jpg";
import Profile from "../../images/profile.jpeg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  function changeLanguage(e) {
    localStorage.setItem("language", e.target.value);
    i18n.changeLanguage(e.target.value);
  }
  const hide = () => {
    const ele = document.getElementById("body");

    ele.classList.toggle("sidebar-collapse");
  };

  const logout = () => {
    localStorage.removeItem("token");
  };

  let birthDetails = JSON.parse(sessionStorage.getItem("details"));

  return (
    <>
      <header className="main-header">
        <a href="#" className="logo blue-bg">
          <span className="logo-mini">
            <img src={l} alt="logo" style={{ height: "40px" }} />
          </span>
          <span className="logo-lg">
            <img src={l} alt="logo" style={{ height: "40px" }} />
          </span>{" "}
        </a>

        <nav className="navbar blue-bg navbar-static-top">
          <ul className="nav navbar-nav pull-left">
            <li onClick={hide}>
              <a
                className="sidebar-toggle"
                data-toggle="push-menu"
                href="#"
              ></a>
            </li>
          </ul>
          <div className="pull-left search-box"></div>

          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              <ul className="navbar-nav me-auto">
                <li
                  className="nav-item dropdown mt-3.5 m-4"
                  onClick={() => navigate("/")}
                  style={{ cursor: "default" }}
                >
                  Home
                </li>
              </ul>
              <ul className="navbar-nav me-auto">
                <li
                  className="nav-item dropdown mt-3.5 m-4"
                  onClick={() => navigate("/admin-dashboard")}
                  style={{ cursor: "default" }}
                >
                  Admin Dashboard
                </li>
              </ul>
              {/*<ul className="navbar-nav me-auto">*/}
              {/*  <li*/}
              {/*    className="nav-item dropdown mt-3.5 m-4"*/}
              {/*    onClick={() => navigate("/subscription")}*/}
              {/*    style={{ cursor: "default" }}*/}
              {/*  >*/}
              {/*    Membership Plans*/}
              {/*  </li>*/}
              {/*</ul>*/}

              {/*<ul className="navbar-nav me-auto">*/}
              {/*  <li*/}
              {/*    className="nav-item dropdown mt-3.5 m-4"*/}
              {/*    onClick={() => navigate("/birth-details-input")}*/}
              {/*    style={{ cursor: "default" }}*/}
              {/*  >*/}
              {/*    Generate Kundali*/}
              {/*  </li>*/}
              {/*</ul>*/}
              {/*<ul className="navbar-nav me-auto">*/}
              {/*  <li*/}
              {/*    className="nav-item dropdown mt-3.5 m-4"*/}
              {/*    onClick={() => navigate("/kundali-list")}*/}
              {/*    style={{ cursor: "default" }}*/}
              {/*  >*/}
              {/*    Our Kundali*/}
              {/*  </li>*/}
              {/*</ul>*/}
              <ul className="navbar-nav me-auto">
                <li className="nav-item dropdown mt-3.5 m-4">
                  <select
                    value={localStorage.getItem("language")}
                    onChange={changeLanguage}
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      border: "none",
                      width: "90px",
                    }}
                  >
                    <option value="en">English</option>
                    <option value="hi">Hindi</option>
                  </select>
                </li>
              </ul>

              <li className="dropdown user user-menu p-ph-res">
                <a
                  href="#"
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  {" "}
                  <img
                    src={Profile}
                    className="user-image"
                    alt="User Image"
                  />{" "}
                  <span className="hidden-xs">{birthDetails?.fullName}</span>{" "}
                </a>
                <ul className="dropdown-menu">
                  <li className="user-header">
                    <div className="pull-left user-img">
                      <img
                        src={Profile}
                        className="img-responsive img-circle"
                        alt="User"
                      />
                    </div>
                    <p className="text-left">{birthDetails?.fullName} </p>
                  </li>

                  <li>
                    <a href="#">
                      <i className="icon-envelope"></i> Name :{" "}
                      {birthDetails?.fullName}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-profile-male"></i> Gender :{" "}
                      {birthDetails?.gender}
                    </a>
                  </li>

                  <li>
                    <a href="#">
                      <i className="icon-profile-male"></i> Date Of Birth :{" "}
                      {birthDetails?.date}/{birthDetails?.month}/
                      {birthDetails?.year}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-profile-male"></i> Time Of Birth :{" "}
                      {birthDetails?.hour}/{birthDetails?.min}/
                      {birthDetails?.sec}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-profile-male"></i> Place :{" "}
                      {birthDetails?.city}
                    </a>
                  </li>

                  <li role="separator" className="divider"></li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
