import Profile from "../../images/profile.jpeg";
import { useTranslation } from "react-i18next";
import "font-awesome/css/font-awesome.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAtom } from "@fortawesome/free-solid-svg-icons";
import vargas from "../../images/fontawesome/vargas.png";
import chart from "../../images/fontawesome/greychart.png";
import friendship from "../../images/fontawesome/friendshipgrey.png";
import sun from "../../images/fontawesome/sun.png";
import planet from "../../images/fontawesome/planetgrey.png";
import dasha from "../../images/fontawesome/vimdasa.png";
import dashboard from "../../images/fontawesome/dashboardgrey.png";

import React from "react";

const Sidebar = () => {
  const { t } = useTranslation();
  let getData = localStorage.getItem("token");
  const user = JSON.parse(getData);
  console.log({ user });
  const showActive = () => {
    const ele = document.getElementById("list");
    ele.classList.toggle("active");
  };

  const showDasha = () => {
    const ele = document.getElementById("dasha");
    ele.classList.toggle("active");
  };

  const subMenu = () => {
    const element = document.getElementById("sub-menu");
    element.classList.toggle("active");
  };

  let birthDetails = JSON.parse(sessionStorage.getItem("details"));
  return (
    <>
      <aside className="main-sidebar" style={{ minHeight: "93vh" }}>
        <div className="sidebar">
          <div className="user-panel">
            <div className="image text-center">
              <img src={Profile} className="img-circle" alt="User Image" />
            </div>
            <div className="info">
              <p>{birthDetails?.fullName}</p>
            </div>
          </div>

          <ul className="sidebar-menu" data-widget="tree">
            <li className="header">{/*<h5>{t("kundaliDetails")}</h5>*/}</li>

            <li className="treeview">
              <a href="/user-dashboard">
                {" "}
                {/*<i className="fa fa-dashboard"></i>{" "}*/}
                <img src={dashboard} alt="dashboard" className="m-1" />
                <span>{t("dashboard")}</span>{" "}
              </a>
            </li>

            <ul className="sidebar-menu" data-widget="tree">
              <li className="treeview" onClick={showActive} id="list">
                {" "}
                <a href="/basic-charts">
                  {" "}
                  <img src={chart} alt="chart" className="m-1" />
                  {/*<i className="fa fa-area-chart"></i>{" "}*/}
                  <span>{t("charts")}</span>{" "}
                  <span className="pull-right-container">
                    {" "}
                    {/*<i className="fa fa-angle-left pull-right"></i>{" "}*/}
                  </span>{" "}
                </a>
              </li>
            </ul>

            <li className="treeview">
              <a href="/vargas">
                {" "}
                <img src={vargas} alt="varga" className="m-1" />
                {/*<i className="fa fa-star"></i> */}
                <span>{t("vargas")}</span>{" "}
              </a>
            </li>

            <li className="treeview">
              <a href="/friendship-table">
                {" "}
                <img src={friendship} alt="grahMaitri" className="m-1" />
                {/*<i className="fa fa-handshake-o"></i>*/}
                <span>{t("grahMaitri")}</span>{" "}
              </a>
            </li>
            <li className="treeview">
              <a href="/shodasvarga-charts">
                {" "}
                <img src={chart} alt="chart" className="m-1" />
                {/*<i className="fa fa-area-chart"></i>*/}
                <span>{t("shodasVargaCharts")}</span>{" "}
              </a>
            </li>

            <li className="treeview">
              <a href="/avakahada-details">
                {" "}
                <img src={planet} alt="planet" className="m-1" />
                {/*<i className="fa fa-arrow-circle-right"></i>*/}
                <span> {t("avakhadaDetails")}</span>{" "}
              </a>
            </li>

            <ul className="sidebar-menu" data-widget="tree">
              <li className="treeview" onClick={showDasha} id="dasha">
                {" "}
                <a href="/dasha">
                  {" "}
                  <img src={sun} alt="planet" className="m-1" />
                  {/*<FontAwesomeIcon icon={faAtom} />{" "}*/}
                  {/*<i className="fa-solid fa-planet-ringed"></i>*/}
                  <span>{t("dasha")}</span>{" "}
                  <span className="pull-right-container">
                    {" "}
                    {/*<i className="fa fa-angle-left pull-right"></i>{" "}*/}
                  </span>{" "}
                </a>
              </li>
            </ul>

            {/*<li className="treeview">*/}
            {/*  <a href="/matchkundali">*/}
            {/*    {" "}*/}
            {/*    <i className="fa fa-arrow-circle-right"></i>*/}
            {/*    <span> {t("matchKundali")}</span>{" "}*/}
            {/*  </a>*/}
            {/*</li>*/}

            {/*<li className="treeview">*/}
            {/*  <a href="/download-kundali">*/}
            {/*    {" "}*/}
            {/*    <i className="fa fa-arrow-circle-right"></i>*/}
            {/*    <span> {t("downloadKundali")}</span>{" "}*/}
            {/*  </a>*/}
            {/*</li>*/}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
