import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

let house = (degrees) => {
  return Math.floor(degrees / 30) + 1;
};
const total = (data) => {
  for (let i = 0; i < data.Sarvashtakvarga.length; i++) {
    let l = Object.entries(data.Sarvashtakvarga[i])[0][1].length;
    let val = Object.entries(data.Sarvashtakvarga[i])[0][1];
    let total = 0;
    for (let j = 0; j < l; j++) {
      total = total + Object.entries(val[j])[0][1];
    }

    Object.entries(data.Sarvashtakvarga[i])[0][1].push({ total: total });
  }
};

const SarvashtakVarga = (props) => {
  total(props);
  let data = props.Sarvashtakvarga;

  const { t } = useTranslation();

  let totalArray = [];

  if (data) {
    for (let m = 0; m < 12; m++) {
      let total = 0;

      Object.entries(props)?.[0]?.[1]?.map((v, i) => {
        total = total + Number(Object.values(Object.values(v)?.[0]?.[m]));
      });

      totalArray.push({
        [Object.keys(
          Object.values(
            Object.values(Object.entries(props)?.[0]?.[1])?.[0]
          )?.[0]?.[m]
        )?.[0]]: total,
      });
    }
    let t = 0;
    totalArray.map((val, ind) => {
      t = t + Number(Object.values(val));
    });
    totalArray.push({ sum: t });
  }
  console.log({ totalArray });
  return (
    <>
      <div className="content">
        <div className="card card-shadow">
          <div className="card-header bg-blue">
            <h5 className="mb-0 text-center boldFont">
              {t("sarvashtakvarga")}
            </h5>
          </div>
          <div className="card-body">
            <div className="content">
              <div className="card">
                <div className="card-body">
                  <h4 className="text-black">
                    {t("sarvashtakvarga")} {t("details")}
                  </h4>

                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="tableCaption tableFontSize"
                          >
                            {t("graha")}
                          </th>
                          <th
                            scope="col"
                            className="tableCaption tableFontSize"
                          >
                            {t("Ar")}
                          </th>
                          <th
                            scope="col"
                            className="tableCaption tableFontSize"
                          >
                            {t("Ta")}
                          </th>
                          <th
                            scope="col"
                            className="tableCaption tableFontSize"
                          >
                            {t("Ge")}
                          </th>
                          <th
                            scope="col"
                            className="tableCaption tableFontSize"
                          >
                            {t("Cn")}
                          </th>
                          <th
                            scope="col"
                            className="tableCaption tableFontSize"
                          >
                            {t("Le")}
                          </th>
                          <th
                            scope="col"
                            className="tableCaption tableFontSize"
                          >
                            {t("Vi")}
                          </th>
                          <th
                            scope="col"
                            className="tableCaption tableFontSize"
                          >
                            {t("Li")}
                          </th>
                          <th
                            scope="col"
                            className="tableCaption tableFontSize"
                          >
                            {t("Sc")}
                          </th>
                          <th
                            scope="col"
                            className="tableCaption tableFontSize"
                          >
                            {t("Sg")}
                          </th>
                          <th
                            scope="col"
                            className="tableCaption tableFontSize"
                          >
                            {t("Cp")}
                          </th>
                          <th
                            scope="col"
                            className="tableCaption tableFontSize"
                          >
                            {t("Aq")}
                          </th>
                          <th
                            scope="col"
                            className="tableCaption tableFontSize"
                          >
                            {t("Pi")}
                          </th>

                          <th
                            scope="col"
                            className="tableCaption tableFontSize"
                          >
                            {t("total")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((val, ind) => {
                          return (
                            <tr>
                              <th scope="row" className="tableFontSize">
                                {t(`${Object.keys(val)}`)}
                              </th>

                              {Object.entries(val)?.[0]?.[1]?.map((v, i) => {
                                return (
                                  <>
                                    <td className="tableCaption tableFontSize">
                                      {Object.entries(v)?.[0]?.[1]}
                                    </td>
                                  </>
                                );
                              })}
                            </tr>
                          );
                        })}
                        <tr>
                          <th className="tableCaption tableFontSize">
                            {t("total")}
                          </th>
                          {totalArray?.map((val, ind) => {
                            return (
                              <>
                                <td className="tableCaption tableFontSize">
                                  {Object.values(val)}
                                </td>
                              </>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SarvashtakVarga;
