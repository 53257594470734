import Header from "./Header";
import Footer from "../Home/Footer";
import Sidebar from "./Sidebar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const KundaliList = () => {
  let userDetails = localStorage.getItem("token");
  const { t } = useTranslation();
  const navigate = useNavigate();
  let user = JSON.parse(userDetails);
  const [details, setDetails] = useState();
  let birthDetails = JSON.parse(sessionStorage.getItem("details"));

  const SaveList = async () => {
    try {
      const res = await axios({
        method: "post",
        url: "https://jyotishpunj.com/api/jp-astrologer/kundli-list",
        data: {
          astrologer_id: user?.astrologer_id,
        },
      });

      if (res.status === 201 && res.data.status === "failed") {
        window.alert(res.data.message);
      }
      if (res.status === 200) {
        setDetails(res?.data);
      }
    } catch (e) {}
  };

  useEffect(() => {
    SaveList();
  }, []);

  const ViewKundali = (
    name,
    gender,
    date,
    month,
    year,
    hour,
    min,
    sec,
    location,
    latitude,
    longitude
  ) => {
    let obj = {
      latitude: latitude,
      longitude: longitude,
      city: location,
      hour: hour,
      min: min,
      sec: sec,
      date: date,
      month: month,
      year: year,
      fullName: name,
      gender: gender,
    };

    sessionStorage.setItem("details", JSON.stringify(obj));

    navigate("/user-dashboard");
  };
  if (details) {
    return (
      <>
        <div className="wrapper boxed-wrapper">
          <Header />
          <Sidebar />
          <div
            className="content-wrapper"
            style={{ height: "auto", minHeight: "93vh" }}
          >
            <div className="content-header sty-one">
              <h1>{t("kundaliList")}</h1>
              <ol className="breadcrumb">
                <li>
                  <a href="#">{t("home")}</a>
                </li>
                <li>
                  <i className="fa fa-angle-right"></i> {t("kundaliList")}
                </li>
              </ol>
            </div>

            <div className="content">
              <div className="card">
                <div className="card-header bg-blue">
                  <h5 className="mb-0 text-center boldFont">
                    {t("kundaliList")}
                  </h5>
                </div>

                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">{t("name")}</th>

                        <th scope="col">{t("gender")}</th>

                        <th scope="col">{t("date")}</th>
                        <th scope="col">{t("time")}</th>
                        <th scope="col">{t("place")}</th>
                        <th scope="col">{t("latitude")}</th>
                        <th scope="col">{t("longitude")}</th>
                        <th scope="col">{t("action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {details?.astroKundliList?.map((v, k) => {
                        return (
                          <tr key={k}>
                            <td>{t(`${v?.astrokundli_name}`)}</td>

                            <td>{v?.astrokundli_gender}</td>

                            <td>
                              {t(
                                `${v?.astrokundli_date}/${v?.astrokundli_month}/${v?.astrokundli_year}`
                              )}
                            </td>
                            <td>
                              {v?.astrokundli_hour}:{v?.astrokundli_min}:
                              {v?.astrokundli_sec}
                            </td>
                            <td>{t(`${v?.astrokundli_location}`)}</td>
                            <td>{t(`${v?.astrokundli_latitude}`)}</td>
                            <td>{t(`${v?.astrokundli_longitude}`)}</td>
                            <td>
                              <button
                                className="tableBtn"
                                onClick={() =>
                                  ViewKundali(
                                    v?.astrokundli_name,
                                    v?.astrokundli_gender,
                                    v?.astrokundli_date,
                                    v?.astrokundli_month,
                                    v?.astrokundli_year,
                                    v?.astrokundli_hour,
                                    v?.astrokundli_min,
                                    v?.astrokundli_sec,
                                    v?.astrokundli_location,
                                    v?.astrokundli_latitude,
                                    v?.astrokundli_longitude
                                  )
                                }
                              >
                                {" "}
                                {t("viewKundali")}
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default KundaliList;
