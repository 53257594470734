import "./App.css";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./routes/ProtectedRoute";
import UnProtectedRoute from "./routes/UnProtectedRoute";
import UnPaidRoute from "./routes/UnPaidRoute";

import { useTranslation } from "react-i18next";

import TodaysPanchang from "./Components/TodaysPanchang";

import MatchKundali from "./Components/MatchKundali";
import MatchKundaliDetails from "./Components/MatchKundaliDetails";

import SignUp from "./Components/SignUp";
import Login from "./Components/Login";
import DownloadPdf from "./Components/DownloadPdf";

import Varga from "./Components/User/Varga/Varga";
import ShodasVargaCharts from "./Components/User/ShodasVargaCharts";
import Dasha from "./Components/User/VimshottariDasha/Dasha";
import FriendShipTable from "./Components/User/GrahMaitri/FriendShipTable";
import AvkahadaDetails from "./Components/User/Avkahada/AvkahadaDetails";
import DownloadKundali from "./Components/User/DownloadKundali";

import UserDashboard from "./Components/User/UserDashboard";

import Home from "./Components/Home/Home";
import Plan from "./Components/Admin/Subscription/Plan";
import BirthDetailsInput from "./Components/Admin/BirthDetailsInput";
import BasicCharts from "./Components/User/Charts/BasicCharts";
import KundaliList from "./Components/Admin/KundaliList";
import Profile from "./Components/Admin/Profile";
import AdminDashboard from "./Components/Admin/AdminDashboard";
import SubscriptionDetails from "./Components/Admin/Subscription/SubscriptionDetails";
import SubscriptionList from "./Components/Admin/Subscription/SubscriptionList";
import PaidRoute from "./routes/PaidRoute";
import Payment from "./Components/Admin/Subscription/Payment";

import PaymentResponse from "./Components/Admin/Subscription/PaymentResponse";
import ContactUs from "./Components/Home/ContactUs";

const App = () => {
  const { t } = useTranslation();

  return (
    <>
      <Suspense fallback="loading">
        <UnProtectedRoute
          disallow={[
            "/admin-dashboard",
            "/downloadpdf",
            "/payment",
            "/matchkundali",
            "/matchkundalidetails",
            "/vargas",
            "/dasha",
            "/shodasvarga-charts",
            "/friendship-table",
            "/download-kundali",
            "/user-dashboard",
            "/kundali-list",
            "/avakahada-details",
            "/admin-profile",
            "/subscription-list",
            "/payment-response",
            "/birth-details-input",
            "/kundali-list",
            "/matchkundali",
          ]}
        />
        <ProtectedRoute disallow={["/login"]} />
        <UnPaidRoute
          disallow={["/birth-details-input", "/kundali-list", "/matchkundali"]}
        />
        <PaidRoute disallow={["/plan"]} />
        <Routes>
          <Route path={"/signup"} element={<SignUp />} />

          <Route path={"/login"} element={<Login />} />
          <Route path={"/downloadpdf"} element={<DownloadPdf />} />
          <Route path={"/payment"} element={<Payment />} />
          <Route path={"/todayspanchang"} element={<TodaysPanchang />} />
          <Route path={"/matchkundali"} element={<MatchKundali />} />
          <Route
            path={"/matchkundalidetails"}
            element={<MatchKundaliDetails />}
          />

          <Route path={"/vargas"} element={<Varga />} />
          <Route path={"/dasha"} element={<Dasha />} />
          <Route path={"/shodasvarga-charts"} element={<ShodasVargaCharts />} />
          <Route path={"/friendship-table"} element={<FriendShipTable />} />
          <Route path={"/avakahada-details"} element={<AvkahadaDetails />} />
          <Route path={"/download-kundali"} element={<DownloadKundali />} />
          <Route path={"/user-dashboard"} element={<UserDashboard />} />
          <Route path={"/admin-dashboard"} element={<AdminDashboard />} />
          <Route path={"/"} element={<Home />} />
          <Route path={"/plan"} element={<Plan />} />
          <Route
            path={"/birth-details-input"}
            element={<BirthDetailsInput />}
          />
          <Route path={"/basic-charts"} element={<BasicCharts />} />
          <Route path={"/kundali-list"} element={<KundaliList />} />
          <Route path={"/admin-profile"} element={<Profile />} />
          <Route
            path={"/subscription-details"}
            element={<SubscriptionDetails />}
          />

          <Route path={"/subscription-list"} element={<SubscriptionList />} />
          <Route path={"/payment"} element={<Payment />} />
          <Route path={"/payment-response"} element={<PaymentResponse />} />
          <Route path={"/contact-us"} element={<ContactUs />} />

          {/*<Route path={"/todaysHoroscope"} element={<TodaysHoroscope />} />*/}
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
