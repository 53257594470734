import Header from "./Admin/Header";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import astrology from "../images/astrology.jpg";
const apiKey = "AIzaSyBUpTf5-NWacvzlIW12l9XcnHgEeVM_u98";
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

const extractAddress = (place) => {
  const address = {
    city: "",
    state: "",
    zip: "",
    country: "",
    plain() {
      const city = this.city ? this.city + ", " : "";
      const zip = this.zip ? this.zip + ", " : "";
      const state = this.state ? this.state + ", " : "";
      return city + zip + state + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    address.longitude = place?.geometry?.location?.lng();
    address.latitude = place?.geometry?.location?.lat();

    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("administrative_area_level_2")) {
      address.state = value;
    }

    if (types.includes("postal_code")) {
      address.zip = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }
  });

  return address;
};

const DownloadPdf = () => {
  const { t } = useTranslation();
  const searchInput = useRef(null);
  const [address, setAddress] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const initMapScript = () => {
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
  };

  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };
  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);
  let lang = localStorage.getItem("language") || "en";
  const d = new Date();
  const [birthDetailsData, setBirthDetailsData] = useState({
    name: "",
    gender: "",
    year: d.getFullYear(),
    month: d.getMonth() + 1,
    date: d.getDate(),
    hour: d.getHours(),
    min: d.getMinutes(),
    sec: d.getSeconds(),
  });

  let name, value;
  const handleBirthDetailsInputs = (e) => {
    name = e.target.name;
    value = e.target.value;

    setBirthDetailsData({ ...birthDetailsData, [name]: value });
  };

  const BirthDetails = async (e) => {
    let city = address?.city;

    e.preventDefault();
    setIsLoading(true);
    try {
      const {
        name,
        gender,
        year,
        month,
        date,
        hour,
        min,
        sec,
        latitude,
        longitude,
      } = birthDetailsData;
      const res = await fetch(`/download-pdf`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          gender,
          year,
          month,
          date,
          hour,
          min,
          sec,
          place: city,
          latitude: address?.latitude,
          longitude: address?.longitude,
          language: lang,
        }),
      });
      const response = await res.json();

      if (res.status === 400 || !response) {
        window.alert(response.message);
      }
      if (res.status === 200) {
      }
    } catch (e) {}
  };

  return (
    <>
      <Header />

      <div className="content-header sty-one">
        <div className="content">
          <div className="card mt-4">
            <div className="card-header bg-blue">
              <h5 className="mb-0 text-center">{t("birthDetails")}</h5>
            </div>
            <div className="card-body">
              <form onSubmit={BirthDetails}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group has-feedback">
                      <label className="control-label">{t("name")}</label>
                      <input
                        className="form-control"
                        placeholder="Name"
                        type="text"
                        name="name"
                        value={birthDetailsData.name}
                        onChange={handleBirthDetailsInputs}
                      />
                      <span
                        className="form-control-feedback"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group has-feedback">
                      <label className="control-label">{t("gender")}</label>
                      <select
                        className="form-control"
                        name="gender"
                        onChange={handleBirthDetailsInputs}
                        value={birthDetailsData.gender}
                      >
                        <option value="select">Select</option>
                        <option value={t("male")}>{t("male")}</option>
                        <option value={t("female")}>{t("female")}</option>
                      </select>
                      {/*<input*/}
                      {/*  className="form-control"*/}
                      {/*  placeholder="Gender"*/}
                      {/*  type="text"*/}
                      {/*  name="gender"*/}
                      {/*  value={birthDetailsData.gender}*/}
                      {/*  onChange={handleBirthDetailsInputs}*/}
                      {/*/>*/}
                      <span
                        className="form-control-feedback"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group has-feedback">
                      <label className="control-label">{t("day")}</label>
                      <input
                        className="form-control"
                        placeholder="Day"
                        type="number"
                        name="date"
                        value={birthDetailsData.date}
                        onChange={handleBirthDetailsInputs}
                      />
                      <span
                        className="form-control-feedback"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group has-feedback">
                      <label className="control-label">{t("month")}</label>
                      <input
                        className="form-control"
                        placeholder="Month"
                        type="number"
                        name="month"
                        value={birthDetailsData.month}
                        onChange={handleBirthDetailsInputs}
                      />
                      <span
                        className="form-control-feedback"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group has-feedback">
                      <label className="control-label">{t("year")}</label>
                      <input
                        className="form-control"
                        placeholder="Year"
                        type="number"
                        name="year"
                        value={birthDetailsData.year}
                        onChange={handleBirthDetailsInputs}
                      />
                      <span
                        className="form-control-feedback"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group has-feedback">
                      <label className="control-label">{t("hours")}</label>
                      <input
                        className="form-control"
                        placeholder="hours"
                        type="number"
                        name="hour"
                        value={birthDetailsData.hour}
                        onChange={handleBirthDetailsInputs}
                      />
                      <span
                        className="form-control-feedback"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group has-feedback">
                      <label className="control-label">{t("minutes")}</label>
                      <input
                        className="form-control"
                        placeholder="minutes"
                        type="number"
                        name="min"
                        value={birthDetailsData.min}
                        onChange={handleBirthDetailsInputs}
                      />
                      <span
                        className="form-control-feedback"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group has-feedback">
                      <label className="control-label">{t("seconds")}</label>
                      <input
                        className="form-control"
                        placeholder="seconds"
                        type="number"
                        name="sec"
                        value={birthDetailsData.sec}
                        onChange={handleBirthDetailsInputs}
                      />
                      <span
                        className="form-control-feedback"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group has-feedback">
                      <label className="control-label">{t("place")}</label>
                      <input
                        className="form-control"
                        ref={searchInput}
                        type="text"
                        placeholder="Enter location...."
                      />
                      <span
                        className="form-control-feedback"
                        aria-hidden="true"
                      ></span>
                    </div>
                  </div>

                  <div className="col-md-12 text-center">
                    <button type="submit" className="btn btn-primary btnStyle">
                      {t("submit")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadPdf;
