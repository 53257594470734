import { useTranslation } from "react-i18next";

const Avakahada = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="content">
        <div className="HideTopPhone">
          <div className="kundli_width_left">
            <table
              id="kundlibasicdetailsWebId"
              width="100%"
              border="0"
              cellSpacing="0"
              cellPadding="0"
              className="kundli_basic_details"
            >
              <tbody>
                <tr>
                  <th scope="col" colSpan="2">
                    {" "}
                    <i className="fa fa-file" style={{ padding: "10px" }}></i>
                    {t("avakhadaDetails")}
                  </th>
                </tr>

                <tr>
                  <td scope="col">{t("paya")}</td>
                  <td scope="col">{t(`${props?.data?.paya}`)}</td>
                </tr>
                <tr>
                  <td scope="col">{t("varna")}</td>
                  <td scope="col">{t(`${props?.data?.varna}`)}</td>
                </tr>
                <tr>
                  <td scope="col">{t("yoni")}</td>
                  <td scope="col">{t(`${props?.data?.yoni}`)}</td>
                </tr>
                <tr>
                  <td scope="col">{t("gana")}</td>
                  <td scope="col">{t(`${props?.data?.gana}`)}</td>
                </tr>
                <tr>
                  <td scope="col">{t("vasya")}</td>
                  <td scope="col">{t(`${props?.data?.vasya}`)}</td>
                </tr>
                <tr>
                  <td scope="col">{t("rashi")}</td>
                  <td scope="col">{t(`${props?.data?.rashi}`)}</td>
                </tr>
                <tr>
                  <td scope="col">{t("nadi")}</td>
                  <td scope="col">{t(`${props?.data?.nadi}`)}</td>
                </tr>
                <tr>
                  <td scope="col">{t("dasaBalance")}</td>
                  <td scope="col">
                    {t(`${props?.data?.dasaBalance?.dasaBalance}`)}
                  </td>
                </tr>

                <tr>
                  <td scope="col">{t("ascendant")}</td>
                  <td scope="col">{t(`${props?.data?.lagna}`)}</td>
                </tr>

                <tr>
                  <td scope="col">{t("lagnLord")}</td>
                  <td scope="col">{t(`${props?.data?.lagnaLord}`)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="kundli_width_left kundli_right_m0">
            <div
              className="text-center rating_num"
              id="kundliYourDetailsWebloaderId"
              style={{ display: "none" }}
            >
              <div className="blocks-large_kundli">
                <div className="block-large_kundli yellow-large_kundli"></div>
                <div className="block-large_kundli gray-large_kundli"></div>
                <div className="clearfix"></div>
              </div>
              <div className="clearfix"></div>
            </div>

            <table
              id="kundliYourDetailsWebId"
              width="100%"
              border="0"
              cellSpacing="0"
              cellPadding="0"
              className="kundli_basic_details"
            >
              <tbody>
                <tr>
                  <th scope="col" colSpan="2">
                    <i className="fa fa-file" style={{ padding: "10px" }}></i>
                    {t("avakhadaDetails")}
                  </th>
                </tr>

                <tr>
                  <td scope="col">{t("zodiac")}</td>
                  <td scope="col">{t(`${props?.data?.rashi}`)}</td>
                </tr>
                <tr>
                  <td scope="col">{t("signLord")}</td>
                  <td scope="col">{t(`${props?.data?.rashiLord}`)}</td>
                </tr>

                <tr>
                  <td scope="col">{t("nakshatra")}</td>
                  <td scope="col">{t(`${props?.data?.nakshatra?.name}`)}</td>
                </tr>

                <tr>
                  <td scope="col">{t("nakshatraPada")}</td>
                  <td scope="col">{t(`${props?.data?.nakshatra?.pada}`)}</td>
                </tr>
                <tr>
                  <td scope="col">{t("nakshatraLord")}</td>
                  <td scope="col">{t(`${props?.data?.nakshatraLord}`)}</td>
                </tr>
                <tr>
                  <td scope="col">{t("julianDay")}</td>
                  <td scope="col">{t(`${props?.data?.julianDay}`)}</td>
                </tr>
                <tr>
                  <td scope="col">{t("sunSign")}</td>
                  <td scope="col">{t(`${props?.data?.sunSign}`)}</td>
                </tr>
                <tr>
                  <td scope="col">{t("ayanamasa")}</td>
                  <td scope="col">{t(`${props?.data?.ayanamasa}`)}</td>
                </tr>
                <tr>
                  <td scope="col">{t("ayanamasaName")}</td>
                  <td scope="col">{t(`${props?.data?.ayanamasaName}`)}</td>
                </tr>
                <tr>
                  <td scope="col">{t("siderialTime")}</td>
                  <td scope="col">{t(`${props?.data?.siderialTime}`)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Avakahada;
