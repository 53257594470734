// import usePayment from "../hooks/usePayment";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

const UnPaidRoute = ({ disallow = [] }) => {
  // const { payment } = usePayment();

  let userDetails = localStorage.getItem("token");

  let user = JSON.parse(userDetails);

  let paymentStatus = user?.md_status;
  let flag = false;
  if (paymentStatus === "Renew") {
    flag = true;
  }

  const location = useLocation();
  const navigate = useNavigate();
  const isDisallowed =
    flag && disallow.some((route) => location.pathname.includes(route));
  useEffect(() => {
    if (isDisallowed) {
      navigate({
        pathname: "/plan",
        state: { from: location },
      });
    }
  }, [isDisallowed, navigate, location.pathname]);
  return null;
};

export default UnPaidRoute;
