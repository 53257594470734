import { useTranslation } from "react-i18next";
import React from "react";

const Chart = (props) => {
  const { t } = useTranslation();
  let birthDetails = JSON.parse(sessionStorage.getItem("details"));
  let language = localStorage.getItem("language");
  return (
    <>
      {/*<div className="content">*/}
      {/*  <div className="card">*/}
      {/*    <div className="card-body">*/}
      {/*      <div className="row">*/}
      {/*        /!*<div className="card-header bg-blue">*!/*/}
      {/*        /!*      <h5 className="mb-0 text-center boldFont">*!/*/}
      {/*        /!*        {t("kundaliList")}*!/*/}
      {/*        /!*      </h5>*!/*/}
      {/*        /!*    </div>*!/*/}
      {/*        <h5 className="text-black heading boldFont">*/}
      {/*          {t(`${props.name}`)}*/}
      {/*        </h5>*/}

      <div className="content">
        <div className="card mt-4">
          <div className="card-header bg-blue">
            <h5 className="mb-0 text-center boldFont">{t(`${props.name}`)}</h5>
          </div>
          <h5 className="text-center m-2">
            <b>{props.property}</b>
          </h5>
          <div className="image text-center m-3">
            {language === "hi" ? (
              <img
                src={"/chart/" + props.hindiChartName}
                alt="Chart"
              />
            ) : (
              <img
                src={"/chart/" + props.chartName}
                alt="Chart"
                // style={{ width: "400px", height: "400px" }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Chart;
