import logo from "../images/high-logo.jpg";
import Header from "./Admin/Header";

import bg from "../images/background.png";

const SignUp = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <Header />

        <div className="login-box" style={{ minHeight: "60vh" }}>
          <div className="login-box-body">
            <center>
              <img
                src={logo}
                className="user-image"
                alt="User Image"
                style={{ width: "100px" }}
              />
            </center>

            <form method="post">
              <div className="form-group has-feedback">
                <input
                  type="text"
                  className="form-control sty1"
                  name="name"
                  placeholder="Name"
                  value=""
                />
              </div>
              <div className="form-group has-feedback">
                <input
                  type="number"
                  className="form-control sty1"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value=""
                />
              </div>
              <div className="form-group has-feedback">
                <input
                  type="email"
                  className="form-control sty1"
                  name="email"
                  placeholder="Email Id"
                  value=""
                />
              </div>
              <div className="form-group has-feedback">
                <input
                  type="password"
                  className="form-control sty1"
                  name="password"
                  placeholder="Password"
                  value=""
                />
              </div>
              <div>
                <div className="col-xs-4 mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block btn-flat"
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </form>

            <div className="mt-3">
              Don't have an account?{" "}
              <a href="/login" className="text-center">
                Login{" "}
              </a>
            </div>
          </div>
        </div>
        <div className="footer-basic">
          <footer style={{ backgroundColor: "white" }}>
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="#">Home</a>
              </li>
              <li className="list-inline-item">
                <a href="#">Services</a>
              </li>
              <li className="list-inline-item">
                <a href="#">About</a>
              </li>
              <li className="list-inline-item">
                <a href="#">Terms</a>
              </li>
              <li className="list-inline-item">
                <a href="#">Privacy Policy</a>
              </li>
            </ul>
            <p className="copyright">Company Name © 2018</p>
          </footer>
        </div>
      </div>
    </>
  );
};

export default SignUp;
