const compareDates = (d1, d2) => {
  let date1 = new Date(d1).getTime();
  let date2 = new Date(d2).getTime();
  let flag = false;
  if (date1 < date2) {
    flag = true;
  } else if (date1 > date2) {
    flag = false;
  } else {
    flag = false;
  }
  return flag;
};

export default compareDates;
