import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../../Home/Footer";
import Sidebar from "../Sidebar";

import AntarDasha from "./AntarDasha";
import PratyantarDasha from "./PratyantarDasha";
import SookshamDasha from "./SookshamDasha";
import PranDasha from "./PranDasha";
import MahaDasha from "./MahaDasha";
import LoadingSpinner from "../../LoadingSpinner";

const Dasha = () => {
  const { t } = useTranslation();
  const [details, setDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  let birthDetails = JSON.parse(sessionStorage.getItem("details"));
  const Dasha = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`/vimshottari-dasha`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          year: birthDetails?.year,
          month: birthDetails?.month,
          date: birthDetails?.date,
          hour: birthDetails?.hour,
          min: birthDetails?.min,
          sec: birthDetails?.sec,
          latitude: birthDetails?.latitude,
          longitude: birthDetails?.longitude,
        }),
      });
      const response = await res.json();

      if (res.status === 400 || !response) {
        window.alert(response.message);
        setIsLoading(false);
      }
      if (res.status === 200) {
        setDetails(response);
        setIsLoading(false);
      }
    } catch (e) {}
  };
  useEffect(() => {
    Dasha().then((r) => console.log(r));
  }, []);
  if (isLoading) {
    return <LoadingSpinner />;
  } else if (!isLoading && details) {
    return (
      <>
        <div
          className="wrapper boxed-wrapper"
          style={{ height: "auto", minHeight: "93vh" }}
        >
          <Header />
          <Sidebar />
          <div className="content-wrapper">
            <div className="content-header sty-one">
              <div className="container">
                <section>
                  <div className="tabs-satrt">
                    <div className="left-atab">
                      <ul
                        className="nav nav-tabs start-project-ul"
                        id="myTab"
                        role="tablist"
                      >
                        <li role="presentation" className="nav-item">
                          <a
                            href="#step1"
                            className="nav-link active st1"
                            data-bs-toggle="tab"
                            aria-controls="step1"
                            role="tab"
                            title="Step 1"
                          >
                            Maha Dasha
                          </a>
                        </li>
                        <li role="presentation" className="nav-item">
                          <a
                            href="#step2"
                            className="nav-link st2"
                            data-bs-toggle="tab"
                            aria-controls="step2"
                            role="tab"
                            title="Step 2"
                          >
                            Antar Dasha
                          </a>
                        </li>
                        <li role="presentation" className="nav-item">
                          <a
                            href="#step3"
                            className="nav-link st3"
                            data-bs-toggle="tab"
                            aria-controls="step3"
                            role="tab"
                            title="Step 3"
                          >
                            Pratyantar Dasha
                          </a>
                        </li>

                        <li role="presentation" className="nav-item lstli">
                          <a
                            href="#step4"
                            className="nav-link st4"
                            data-bs-toggle="tab"
                            aria-controls="step4"
                            role="tab"
                            title="Complete"
                          >
                            Sooksham Dasha
                          </a>
                        </li>
                        <li role="presentation" className="nav-item lstli">
                          <a
                            href="#step5"
                            className="nav-link st5"
                            data-bs-toggle="tab"
                            aria-controls="step5"
                            role="tab"
                            title="Complete"
                          >
                            Pran Dasha
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="tabs-pro form-style">
                      <div className="tab-content">
                        <div
                          className="tab-pane  active"
                          role="tabpanel"
                          id="step1"
                        >
                          <MahaDasha mahaDasha={details?.mahaDasha} />
                        </div>

                        <div className="tab-pane" role="tabpanel" id="step2">
                          <AntarDasha antarDasha={details?.antarDasha} />
                        </div>

                        <div className="tab-pane" role="tabpanel" id="step3">
                          <PratyantarDasha
                            pratyantarDasha={details?.pratyantarDasha}
                          />
                        </div>

                        <div className="tab-pane" role="tabpanel" id="step4">
                          <SookshamDasha
                            sookshamaDasha={details?.sookshamaDasha}
                          />
                        </div>

                        <div className="tab-pane" role="tabpanel" id="step5">
                          <PranDasha pranDasha={details?.pranDasha} />
                        </div>

                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};
export default Dasha;
