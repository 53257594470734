import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Header from "./Header";
import Footer from "../Home/Footer";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";

const DownloadFile = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <div
        className="wrapper boxed-wrapper"
        style={{ height: "auto", minHeight: "93vh" }}
      >
        <Header />
        <Sidebar />
        <div className="content-wrapper">
          <div className="content-header sty-one">
            <h1> {t("downLoadKundali")}</h1>
            <ol className="breadcrumb">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <i className="fa fa-angle-right"></i> {t("downLoadKundali")}
              </li>
            </ol>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="content-header sty-one">
            <div className="content">
              <div className="card mt-4">
                <div className="card-header bg-blue">
                  <h5 className="mb-0 text-center">{t("downLoadKundali")}</h5>
                </div>

                <a
                  href={
                    "/kundali-pdf/" +
                    location?.state?.fileName
                  }
                  target="_blank"
                  class="buttonDownload"
                  download
                >
                  Download Kundali
                  {/*<button>Download File</button>*/}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default DownloadFile;
