import Header from "./Admin/Header";
import Footer from "./Home/Footer";
import Sidebar from "./Admin/Sidebar";
import { useTranslation } from "react-i18next";

const MatchKundaliDetails = () => {
  const { t } = useTranslation();
  let kundaliDetails = JSON.parse(
    sessionStorage.getItem("matchKundaliDetails")
  );

  return (
    <>
      <div className="wrapper boxed-wrapper">
        <Header />
        <Sidebar />

        <div
          className="content-wrapper"
          style={{ height: "auto", minHeight: "93vh" }}
        >
          <div className="content-header sty-one">
            <h1>{t("gunaMilan")}</h1>
            <ol className="breadcrumb">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <i className="fa fa-angle-right"></i>
                {t("gunaMilan")}
              </li>
            </ol>
          </div>
          <div className="content">
            <div className="card card-shadow">
              <div className="card-header bg-blue">
                <h5 className="mb-0 text-center boldFont">{t("gunaMilan")}</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="text-black">
                          {t("birthDetailsOfBoyAndGirl")}
                        </h4>

                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <th scope="col"></th>
                              <th scope="col">Name</th>
                              <th scope="col">Date</th>
                              <th scope="col">Time</th>
                              <th scope="col">Place</th>
                              <th scope="col">Longitude</th>
                              <th scope="col">Latitude</th>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Boy</th>
                                <td>{kundaliDetails?.boyName}</td>
                                <td>{`${kundaliDetails?.boyDate}/${kundaliDetails?.boyMonth}/${kundaliDetails?.boyYear}`}</td>
                                <td>{`${kundaliDetails?.boyHour}/${kundaliDetails?.boyMin}/${kundaliDetails?.boySec}`}</td>
                                <td>{kundaliDetails?.boyCity}</td>
                                <td>{kundaliDetails?.boyLongitude}</td>
                                <td>{kundaliDetails?.boyLatitude}</td>
                              </tr>
                              <tr>
                                {" "}
                                <th scope="row">Girl</th>
                                <td>{kundaliDetails?.girlName}</td>
                                <td>{`${kundaliDetails?.girlDate}/${kundaliDetails?.girlMonth}/${kundaliDetails?.girlYear}`}</td>
                                <td>{`${kundaliDetails?.girlHour}/${kundaliDetails?.girlMin}/${kundaliDetails?.girlSec}`}</td>
                                <td>{kundaliDetails?.girlCity}</td>
                                <td>{kundaliDetails?.girlLongitude}</td>
                                <td>{kundaliDetails?.girlLatitude}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="text-black">{t("gunaMilan")}</h4>

                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <th scope="col">Guna</th>
                              <th scope="col">Boy</th>
                              <th scope="col">Girl</th>
                              <th scope="col">Maximum Obtained</th>
                              <th scope="col">Obtained Points</th>
                              <th scope="col">Area Of Life</th>
                            </thead>
                            <tbody>
                              {kundaliDetails?.result?.map((val, index) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{val.guna}</td>
                                      <td>{val.boyGuna}</td>
                                      <td>{val.girlGuna}</td>
                                      <td>{val.maximumObtained}</td>
                                      <td>{val.obtainedPoint}</td>
                                      <td>{val.areaOfLife}</td>
                                    </tr>
                                  </>
                                );
                              })}

                              <tr></tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MatchKundaliDetails;
