import Header from "../Header";
import Footer from "../../Home/Footer";
import Sidebar from "../Sidebar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import axios from "axios";

const SubscriptionList = () => {
  let userDetails = localStorage.getItem("token");
  const { t } = useTranslation();

  let user = JSON.parse(userDetails);

  const [details, setDetails] = useState();

  const SaveList = async () => {
    try {
      const res = await axios({
        method: "post",
        url: "https://jyotishpunj.com/api/jp-astrologer/membership-history",
        data: {
          astrologer_id: user?.astrologer_id,
        },
      });

      if (res.data.status === 201 && res.data.status === "failed") {
        window.alert(res.data.message);
      }
      if (res.data.status === "success") {
        setDetails(res.data);
      }
    } catch (e) {}
  };

  useEffect(() => {
    SaveList();
  }, []);

  if (details) {
    return (
      <>
        <div className="wrapper boxed-wrapper">
          <Header />
          <Sidebar />
          <div
            className="content-wrapper"
            style={{ height: "auto", minHeight: "93vh" }}
          >
            <div className="content-header sty-one">
              <h1>{t("subscriptionList")}</h1>
              <ol className="breadcrumb">
                <li>
                  <a href="#">{t("home")}</a>
                </li>
                <li>
                  <i className="fa fa-angle-right"></i> {t("subscriptionList")}
                </li>
              </ol>
            </div>

            <div className="content">
              <div className="card card-shadow">
                <div className="card-header bg-blue">
                  <h5 className="mb-0 text-center boldFont">
                    {t("subscriptionList")}
                  </h5>
                </div>
                <div className="table-responsive">
                  <table className="table m-3">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">{t("astrologerId")}</th>

                        <th scope="col">{t("membershipPlan")}</th>

                        <th scope="col">{t("membershipPrice")}</th>
                        <th scope="col">{t("membershipStartDate")}</th>
                        <th scope="col">{t("membershipEndDate")}</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {details?.membershipHistory?.map((v, k) => {
                        return (
                          <tr key={k}>
                            <td>{t(`${v?.membership_astrologerid}`)}</td>
                            <td>{t(`${v?.membership_name}`)}</td>

                            <td>{v?.membership_price}</td>

                            <td>{t(`${v?.membership_startdate}`)}</td>

                            <td>{t(`${v?.membership_enddate}`)}</td>

                            <td></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/*<div className="content-header sty-one">*/}
            {/*  <div className="content">*/}
            {/*    <div className="card ">*/}
            {/*      <div className="card-header bg-blue">*/}
            {/*        <h5 className="mb-0 text-center boldFont">*/}
            {/*          {t("subscriptionList")}*/}
            {/*        </h5>*/}
            {/*      </div>*/}

            {/*      <div className="table-responsive">*/}
            {/*        <table className="table m-3">*/}
            {/*          <thead className="text-center">*/}
            {/*            <tr>*/}
            {/*              <th scope="col">{t("astrologerId")}</th>*/}

            {/*              <th scope="col">{t("membershipPlan")}</th>*/}

            {/*              <th scope="col">{t("membershipPrice")}</th>*/}
            {/*              <th scope="col">{t("membershipStartDate")}</th>*/}
            {/*              <th scope="col">{t("membershipEndDate")}</th>*/}
            {/*            </tr>*/}
            {/*          </thead>*/}
            {/*          <tbody className="text-center">*/}
            {/*            {details?.membershipHistory?.map((v, k) => {*/}
            {/*              return (*/}
            {/*                <tr key={k}>*/}
            {/*                  <td>{t(`${v?.membership_astrologerid}`)}</td>*/}
            {/*                  <td>{t(`${v?.membership_name}`)}</td>*/}

            {/*                  <td>{v?.membership_price}</td>*/}

            {/*                  <td>{t(`${v?.membership_startdate}`)}</td>*/}

            {/*                  <td>{t(`${v?.membership_enddate}`)}</td>*/}

            {/*                  <td></td>*/}
            {/*                </tr>*/}
            {/*              );*/}
            {/*            })}*/}
            {/*          </tbody>*/}
            {/*        </table>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default SubscriptionList;
