import AwesomeSlider from "react-awesome-slider";
import banner3 from "../../images/Banner/banner3.png";
import banner1 from "../../images/Banner/banner1.png";
import banner2 from "../../images/Banner/banner2.png";

import "react-awesome-slider/dist/styles.css";

const Banner = () => {
  return (
    <>
      <AwesomeSlider>
        <div className="banner" data-src={banner3} />
        <div className="banner" data-src={banner1} />
        <div className="banner" data-src={banner2} />
      </AwesomeSlider>
    </>
  );
};

export default Banner;
