import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../../Home/Footer";
import Sidebar from "../Sidebar";

import { useTranslation } from "react-i18next";
import axios from "axios";
import compareDates from "../../../utils/CompareDates";
import { useNavigate } from "react-router-dom";
const Profile = () => {
  let userDetails = localStorage.getItem("token");
  const { t } = useTranslation();
  let user = JSON.parse(userDetails);
  const [details, setDetails] = useState();

  const navigate = useNavigate();
  const Subscription = () => {
    navigate("/plan");
  };
  const SubscriptionDetails = async () => {
    try {
      const res = await axios({
        method: "post",
        url: "https://jyotishpunj.com/api/jp-astrologer/membership-details",
        data: {
          astrologer_id: user?.astrologer_id,
        },
      });

      if (res.data.status === "failed") {
        // window.alert(res.data.message);
        window.alert("You have no subscription details");
      }
      if (res.data.status === "success") {
        setDetails(res.data);
      }
    } catch (e) {}
  };

  useEffect(() => {
    SubscriptionDetails();
  }, []);

  let endDate = details?.membershipDetails?.membership_enddate;
  let dateString = `${endDate?.split("-")[2]}/${endDate?.split("-")[1]}/${
    endDate?.split("-")[0]
  }`;

  let res = compareDates(dateString, new Date().toLocaleDateString());
  // if (details) {
  return (
    <>
      <div className="wrapper boxed-wrapper">
        <Header />
        <Sidebar />
        <div
          className="content-wrapper"
          style={{ height: "auto", minHeight: "93vh" }}
        >
          <div className="content-header sty-one">
            <h1>{t("subscription")}</h1>
            <ol className="breadcrumb">
              <li>
                <a href="#">{t("home")}</a>
              </li>
              <li>
                <i className="fa fa-angle-right"></i> {t("subscription")}
              </li>
            </ol>
          </div>

          <div className="content">
            <div className="card card-shadow">
              <div className="card-header bg-blue">
                <h5 className="mb-0 text-center boldFont">
                  {t("subscription")} {t("details")}
                </h5>
              </div>
              <div className="card-body">
                <a href="/subscription-list">
                  <u>Check Subscription History</u>
                </a>
                <div className="row">
                  <div className="col-lg-4">
                    <fieldset className="form-group m-3">
                      <label className="m-2"> Astrologer Id </label>
                      <input
                        type="text"
                        value={
                          details?.membershipDetails?.membership_astrologerid
                        }
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>

                  <div className="col-lg-4">
                    <fieldset className="form-group m-3">
                      <label className="m-2"> Membership Plan </label>
                      <input
                        type="text"
                        value={details?.membershipDetails?.membership_name}
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>

                  <div className="col-lg-4">
                    <fieldset className="form-group m-3">
                      <label className="m-2"> Membership Price </label>
                      <input
                        type="text"
                        value={details?.membershipDetails?.membership_payamt}
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>

                  <div className="col-lg-4">
                    <fieldset className="form-group m-3">
                      <label className="m-2"> Membership Start Date </label>
                      <input
                        type="text"
                        value={details?.membershipDetails?.membership_startdate}
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>

                  <div className="col-lg-4">
                    <fieldset className="form-group m-3">
                      <label className="m-2"> Membership End Date </label>
                      <input
                        type="text"
                        value={details?.membershipDetails?.membership_enddate}
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>
                </div>
                {res ? (
                  <div className="text-center  m-2">
                    <h5 className="blink">
                      Your subscription has expired. Please click below{" "}
                      <span className="m-2">
                        <i className="fa fa-arrow-down"></i>
                      </span>{" "}
                      to renew your subscription !!
                    </h5>

                    <button
                      type="button"
                      className="subscriptionBtnStyle"
                      onClick={() => Subscription()}
                    >
                      Renew
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
  // }
};

export default Profile;
