import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "../Home/Footer";
import Sidebar from "./Sidebar";

import { useTranslation } from "react-i18next";

const Profile = () => {
  let userDetails = localStorage.getItem("token");
  const { t } = useTranslation();
  let user = JSON.parse(userDetails);

  return (
    <>
      <div className="wrapper boxed-wrapper">
        <Header />
        <Sidebar />
        <div
          className="content-wrapper"
          style={{ height: "auto", minHeight: "93vh" }}
        >
          <div className="content-header sty-one">
            <h1>{t("profile")}</h1>
            <ol className="breadcrumb">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <i className="fa fa-angle-right"></i>
                {t("profile")}
              </li>
            </ol>
          </div>

          <div className="content">
            <div className="card card-shadow">
              <div className="card-header bg-blue">
                <h5 className="mb-0 text-center boldFont">
                  {t("personalInformation")}
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4">
                    <fieldset className="form-group">
                      <label> Astrologer Id </label>
                      <input
                        type="text"
                        value={user?.astrologer_id}
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>

                  <div className="col-lg-4">
                    <fieldset className="form-group">
                      <label>Full Name </label>
                      <input
                        type="text"
                        value={user?.astrologer_name}
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>

                  <div className="col-lg-4">
                    <fieldset className="form-group">
                      <label> Email </label>
                      <input
                        type="text"
                        value={user?.astrologer_email}
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>

                  <div className="col-lg-4">
                    <fieldset className="form-group">
                      <label> Mobile/Phone </label>
                      <input
                        type="text"
                        value={user?.astrologer_mobile}
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>

                  <div className="col-lg-4">
                    <fieldset className="form-group">
                      <label> Address </label>
                      <input
                        type="text"
                        value={user?.astrologer_address}
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>

                  <div className="col-lg-4">
                    <fieldset className="form-group">
                      <label> Call Rate </label>
                      <input
                        type="text"
                        value={user?.astrologer_call_rate}
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>

                  <div className="col-lg-4">
                    <fieldset className="form-group">
                      <label> Chat Rate </label>
                      <input
                        type="text"
                        value={user?.astrologer_call_rate}
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>

                  <div className="col-lg-4">
                    <fieldset className="form-group">
                      <label> Rating </label>
                      <input
                        type="text"
                        value={user?.astrologer_rating}
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>

                  <div className="col-lg-4">
                    <fieldset className="form-group">
                      <label> Approval Status </label>
                      <input
                        type="text"
                        value={user?.astrologer_rating}
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>

                  <div className="col-lg-6">
                    <fieldset className="form-group">
                      <label> Short Description </label>
                      <textarea
                        value={user?.astrologer_short_details}
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>

                  <div className="col-lg-6">
                    <fieldset className="form-group">
                      <label> Description </label>
                      <textarea
                        value={user?.astrologer_long_details}
                        className="form-control"
                        readOnly
                      />
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Profile;
