import Header from "./Header";
import Sidebar from "./Sidebar";

import match from "../../images/fontawesome/match.png";
import chart from "../../images/fontawesome/chart.png";
import profile from "../../images/fontawesome/profile.png";

import Footer from "../Home/Footer";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let userDetails = localStorage.getItem("token");
  let user = JSON.parse(userDetails);

  // let endDate = user?.md_enddate;
  // let dateString = `${endDate?.split("-")[2]}/${endDate?.split("-")[1]}/${
  //   endDate?.split("-")[0]
  // }`;
  //
  // let res = compareDates(dateString, new Date().toLocaleDateString());
  let res = false;
  if (user?.md_status === "Renew") {
    res = true;
  }
  const Subscription = () => {
    navigate("/plan");
  };
  return (
    <>
      <div className="wrapper boxed-wrapper">
        <Header />
        <Sidebar />
        <div
          className="content-wrapper"
          style={{ height: "auto", minHeight: "93vh" }}
        >
          <div className="content-header sty-one">
            <h1> {t("adminDashboard")}</h1>
            <ol className="breadcrumb">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <i className="fa fa-angle-right"></i> {t("adminDashboard")}
              </li>
            </ol>
          </div>

          {/*</div>*/}
          {/*<div className="content-wrapper">*/}
          <div className="row">
            {res ? (
              <div className="text-center  m-2">
                <h5 className="renew">
                  Your subscription has expired. Please click below{" "}
                  <span className="m-2">
                    <i className="fa fa-arrow-down"></i>
                  </span>
                  to renew your subscription !!
                </h5>

                <button
                  type="button"
                  className="subscriptionBtnStyle"
                  onClick={() => Subscription()}
                >
                  Renew
                </button>
              </div>
            ) : (
              ""
            )}
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-3">
              <a href="admin-profile">
                <div className="card m-3 dashboardStyle ">
                  <div className="card-body m-3">
                    {" "}
                    <div className="info-widget-text row">
                      <div className="center-align m-1">
                        <img src={profile} alt="profile" className="m-1" />
                        {/*<i className="fa fa-pie-chart"></i>*/}
                      </div>
                      <div className="center-align">
                        <span>Profile</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-3">
              <a href="birth-details-input">
                <div className="card m-3 dashboardStyle ">
                  <div className="card-body m-3">
                    <div className="info-widget-text row">
                      <div className="center-align m-1">
                        <i
                          className="fa fa-list m-1"
                          style={{ fontSize: "24px" }}
                        ></i>
                        {/*<img src={chart} alt="chart" className="m-1" />*/}
                      </div>
                      <div className="center-align">
                        <span>Generate Kundali </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-3">
              <a href="kundali-list">
                <div className="card m-3 dashboardStyle ">
                  <div className="card-body m-3">
                    <div className="info-widget-text row">
                      <div className="center-align m-1">
                        <img src={chart} alt="chart" className="m-1" />
                      </div>
                      <div className="center-align">
                        <span>Our Kundali </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-3">
              <a href="matchkundali">
                <div className="card m-3 dashboardStyle ">
                  <div className="card-body m-3">
                    <div className="info-widget-text row">
                      <div className="center-align m-1">
                        <img src={match} alt="chart" className="m-1" />
                      </div>
                      <div className="center-align">
                        <span>Match Kundali </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminDashboard;
